.img-teaser {
  position: relative;
}
.img-teaser:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #000;
  opacity: .6;
  transition: opacity 300ms ease-in-out;
}
.img-teaser:hover:after {
  opacity: 0;
}
.img-teaser__content {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
  padding: rem(20) rem(85) rem(20) rem(36);
}
.img-teaser__content__title {
  font-size: rem(18);
  line-height: (32/26);

  @media screen and (min-width: 1200px) {
    font-size: rem(24);
  }

  @media screen and (min-width: 1400px) {
    font-size: rem(26);
  }
}
.img-teaser__content__date {
  position: relative;
  padding-bottom: rem(2);
}
.img-teaser__content__date:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: rem(55);
  height: rem(1);
  background: #fff;
}


.teaser__badge {
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  transform: rotate(-90deg) translateX(-100%);
  transform-origin: top left;
  padding: rem(7) rem(20);
  font-size: rem(15);
  line-height: rem(18);
}