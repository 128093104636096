.thumb-slider {
    font-size: 0; /* fix slick slider height bug */
}
.thumb-slider__main__item {
    margin: 0;
}
.thumb-slider__thumbs__item {
    margin: 0;
    margin-bottom: rem(5);
    position: relative;
}
.thumb-slider__thumbs__item:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #000;
    opacity: .3;
    transition: opacity 200ms ease-in-out;
    z-index: 1;
}
.slick-current .thumb-slider__thumbs__item:before {
    opacity: 0;
}
.thumb-slider__thumbs__item:after {
    content: '';
    position: absolute;
    top: rem(6);
    bottom: rem(6);
    left: rem(6);
    right: rem(6);
    border: rem(1) solid $secondary;
    transition: opacity 200ms ease-in-out;
    opacity: 0;
}
.slick-current .thumb-slider__thumbs__item:after {
    opacity: 1;
}
.thumb-slider .slick-arrow {
    @media screen and (min-width: 768px) {
        left: 50%;
        transform: translateX(-50%) translateY(-50%) rotate(90deg);
        top: 0;
        color: $secondary;
        font-size: rem(34);
        width: rem(40);
        height: rem(46);
        padding: 0;
    }
}
.thumb-slider .slick-arrow:before {
    @media screen and (min-width: 768px) {
        content: $icon-arrow-down;
        font-family: iconfont;
        color: #fff;
        position: absolute;
        transform: rotate(90deg);
        z-index: 1;
        font-size: rem(10);
        left: rem(8);
        top: rem(15);
    }
}
.thumb-slider .slick-arrow.slick-next {
    @media screen and (min-width: 768px) {
        right: auto;
        top: auto;
        left: 50%;
        bottom: rem(5);
        transform: translateX(-50%) translateY(50%) rotate(-90deg);
    }
}

@media screen and (max-width: 767px) {
    .thumb-slider .slick-arrow {
        top: 0;
        margin-top: 30%;
    }
}