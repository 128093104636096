.slick-dots {
  position: absolute;
  top: 0;
  left: 50%;
  list-style: none;
  text-align: right;
  max-width: rem(1480);
  width: 100%;
  transform: translateX(-50%);
  padding: 0 rem(20);
}
.slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: rem(15);
  z-index: 1;
  font-size: rem(24);
  color: #fff;
  transition: color 200ms ease-in-out, opacity 200ms ease-in-out;
    line-height: 1;
    padding: rem(15) 0;

     @media screen and (min-width: 768px) {
         font-size: rem(34);
         left: rem(30);
     }

     @media screen and (min-width: 1200px) {
         left: rem(50);
     }
}
.slick-arrow:hover {
  color: $secondary;
}
.slick-arrow.slick-next {
  left: auto;
  right: rem(15);

    @media screen and (min-width: 768px) {
        left: auto;
        right: rem(30);
    }

    @media screen and (min-width: 1200px) {
        left: auto;
        right: rem(50);
    }
}
.slick-arrow.slick-disabled {
  opacity: 0;
  pointer-events: none;
}
.slick-dots li {
  display: inline-block;
}
.slick-dots li + li {
  margin-left: rem(3);
}
.slick-dots button {
  border: none;
  font-size: 0;
  color: transparent;
  padding: 0;
  cursor: pointer;
  width: rem(60);
  height: rem(5);
  background: #fff;
  position: relative;
}
.slick-dots button:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $secondary;
  transform: scaleY(0);
  transform-origin: bottom center;
  transition: transform 250ms ease-in-out;
}

.slick-dots .slick-active button:before {
  transform: scaleY(1);
}