.technogramm-teaser {

}
.technogramm__date,
.technogramm__btn {
  display: block;
  padding:.625rem .5rem .5rem .5rem;
  font-size: 13px;
  color:#fff;
  text-align: center;
  border-right:1px solid #fff;
  background:$secondary;
  -webkit-appearance: none;
}
.technogramm__date {
  border-right:0;
  background:#272727;
}
.technogramm__btn>.icon {
  position: relative;
  margin-right:.5rem;
  line-height:1px;
}
.technogramm__btn>.icon-ansicht {
  font-size: 1rem;
  top: .125rem;
}

/* overlay */
.issuu-overlay {
  position: fixed;
  left:0;
  right:0;
  top:0;
  bottom:0;
  z-index:999;
  display: none;
  background: #272727;
}
.issuu-overlay.is-visible {
  display:block;
}
.issuu-overlay__close {
  color:#fff;
  font-size: 1.5rem;
  position: absolute;
  right: 0rem;
  top: 1rem;
  padding: 1.5rem;
  opacity: .85;
}
.issuu-overlay__close:hover,
.issuu-overlay__close:focus {
  color:#fff;
  opacity: 1;
}