.testimonial__text {
    position: relative;
    font-size: rem(16);
    padding: rem(12) 0;

    @media screen and (min-width: 768px) {
        font-size: rem(22);
    }
}
.testimonial__text:before,
.testimonial__text:after {
    content: $icon-quote;
    font-family: iconfont;
    position: absolute;
    top: rem(-15);
    left: 0;
    color: $secondary;
    font-size: rem(18);

    @media screen and (min-width: 768px) {
        top: rem(-30);
        font-size: rem(28);
    }
}
.testimonial__text:after {
    top: auto;
    left: auto;
    bottom: rem(-5);
    right: 0;
    transform: rotate(180deg);

    @media screen and (min-width: 768px) {
        bottom: rem(-20);
    }
}
.testimonial__author {
    position: relative;
    padding-top: rem(10);
    font-size: rem(14);

    @media screen and (min-width: 768px) {
        padding-top: rem(18);
        font-size: rem(15);
    }
}
.testimonial__author:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: rem(100);
    height: rem(1);
    background: $color-bg-default;
    opacity: .3;
}