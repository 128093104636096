@media screen and (min-width: 768px) {
    .text-with-media {
        overflow: hidden;
    }
    .text-with-media__media-container {
        float: right;
        margin-left: rem(38);
        max-width: 47%;

    }
    .text-with-media__media {
        display: block;
        margin-bottom: rem(10);
        max-width: 100%;
        height: auto;
    }
    .text-with-img--media-left .text-with-media__media-container {
        float: left;
        margin-right: rem(38);
        margin-left: 0;
    }
    .text-with-media__media.text-with-media__media--video {
        width: rem(420);
        height: auto;
    }
}

@media screen and (max-width: 767px) {
    .text-with-media {
        display: flex;
        flex-direction: column;
    }
    .text-with-media__media-container {
        order: 2;
        margin-top: rem(30);
    }
    .text-with-media__media {
        width: 100%;
    }
}