.password-strength {
    font-size: rem(14);
}
.password-strength__item + .password-strength__item {
    margin-top: rem(5);
}
.password-strength__item__icon {
    color: $danger;
    font-size: rem(10);
    margin-right: rem(6);
    vertical-align: 0.1em;
}
.password-strength__item__icon:before {
    content: $icon-close;
}
.is-valid .password-strength__item__icon {
    color: $secondary;
}
.is-valid .password-strength__item__icon:before {
    content: $icon-checkmark;
}