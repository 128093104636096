.teaser-slider {
    overflow: hidden;

    @media screen and (min-width: 768px) {
        padding: 0 rem(65);
    }

    @media screen and (min-width: 1200px) {
        padding: 0 rem(115);
    }
}
.teaser-slider .slick-list {
    overflow: visible;
}
.teaser-slider .slick-slide {
    opacity: .6;
    transition: opacity 200ms ease-in-out;
    pointer-events: none;

    @media screen and (min-width: 768px) {
        padding: 0 rem(10);
    }

    @media screen and (min-width: 1400px) {
        padding: 0 rem(25);
    }
}
.teaser-slider .slick-active {
    opacity: 1;
    pointer-events: auto;
}