.loading-spinner-container{
    position: relative;
}
.loading-spinner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.6);
    z-index: 2;
}
.loading-spinner__dots {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
}
.loading-spinner__dots__icon {
    border-radius: 100%;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    color: $primary;
    font-size: rem(20);
    display: inline-block;
}
.loading-spinner__dots__icon:nth-child(1) {
    animation-delay: -0.32s;
}
.loading-spinner__dots__icon:nth-child(2) {
    animation-delay: -0.16s;
}
@keyframes sk-bouncedelay {
    0%, 80%, 100% { transform: scale(0);}
    40% { transform: scale(1.0);}
}