.dropdown__select {
    background: #fff;
    border: rem(1) solid #fff;
    box-shadow: 0 rem(-1) 0 0 $color-grey;
    color: $secondary;
    width: 100%;
    font-size: rem(16);
    line-height: (18/16);
    padding: rem(15);
    cursor: pointer;
    position: relative;
}
.dropdown__select:after {
    font-family: "iconfont";
    content: $icon-arrow-down;
    speak: none;
    position: absolute;
    top: 50%;
    right: rem(10);
    transform: translateY(-50%);
    pointer-events: none;
    font-size: rem(12);
    color: $secondary;

    @media screen and (min-width: 768px) {
        right: rem(15);
    }
}
.dropdown__select + .dropdown-menu {
    width: 100%;
}
.dropdown-menu {
    box-shadow: 0 rem(2) rem(5) rem(-1) rgba(0,0,0,0.2);
}
.dropdown-menu__list {
    padding: 0 rem(15);
}
.dropdown-menu__list li + li {
    margin-top: rem(6);
}