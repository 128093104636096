.hexagon-img {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 118%;
    margin-bottom: rem(20);

    @media screen and (min-height: 768px) {
        margin-bottom: 0;
    }
}
.hexagon-img__svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.hexagon-img__svg__border {
    position: absolute;
    top: 12px;
    left: 3px;
    bottom: 3px;
    right: 3px;
    background-size:contain;
    background-repeat: no-repeat;
    @media screen and (max-width: 992px) {
        top: 9px;
    }
}
