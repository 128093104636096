.nav-tabs {
    margin-bottom: rem(20);

    @media screen and (min-width: 768px) {
        margin-bottom: rem(35);
    }
}
.nav-tabs .nav-item {
    padding: 0 rem(10);

    @media screen and (min-width: 768px){
        padding: 0 rem(20);
    }
}
.nav-tabs .nav-link {
  font-size: rem(15);
  line-height: (18/15);
  position: relative;
    padding: rem(8) rem(5);

    @media screen and (min-width: 768px) {
        padding: $nav-link-padding-y $nav-link-padding-x;
    }
}
.nav-tabs .nav-link:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: rem(5);
  right: rem(5);
  height: rem(2);
  background: $secondary;
  transform: scaleX(0);
  transition: transform 200ms ease-in-out;
  transform-origin: center;

    @media screen and (min-width: 768px) {
        left: $nav-link-padding-x;
        right: $nav-link-padding-x;
    }
}
.active.nav-link:after {
  transform: scaleX(1);
}

/* icon tabs */
.nav-tabs--icons {
    margin: 0 rem(-10) rem(15);
}
.nav-tabs.nav-tabs--icons .nav-item {
    padding: 0;
}
.nav-tabs--icons .nav-link {
    padding: 0 rem(10);
    font-size: rem(24);
    color: $color-grey-medium;
}
.nav-tabs--icons .nav-link:after {
    content: none;
}
.nav-tabs--icons .nav-link.active {
    color: $primary-link-color;
}

/* nav pills */
.nav-pills .nav-link {
    background: #fff;
    font-size: rem(18);
    line-height: calc(26/20);
    padding: rem(12) rem(30) rem(12) rem(12);
    position: relative;

    @media screen and (min-width: 768px) {
        font-size: rem(20);
        padding: rem(20) rem(40) rem(15) rem(20);
    }
}
.nav-pills .nav-link:hover {
    background: $color-grey;
}
.nav-pills .nav-link__icon {
    position: absolute;
    right: rem(12);
    font-size: rem(17);
    top: 50%;
    transform: translateY(-50%);

    @media screen and (min-width: 768px) {
        right: rem(20);
    }
}
.nav-pills .nav-item + .nav-item {
    margin-top: rem(10);
}