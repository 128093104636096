.solution-box {
    position: relative;
}
.solution-box__bg {
    width: 100%;
    background-size: cover;
    background-position: center;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
}
.solution-box__bg--half {
    width: 50%;
}
.solution-box__content {
    padding: rem(15);

    @media screen and (min-width: 768px) {
        padding: rem(40) rem(40) rem(50);
        min-height: rem(470);
        height: 100%;
    }

    @media screen and (min-width: 992px) {
        min-height: rem(520);
    }

    @media screen and (min-width: 1200px) {
        min-height: rem(720);
    }

    @media screen and (min-width: 1400px) {
        padding: rem(60) rem(60) rem(70);
    }
}
.solution-box__content__title {
  font-size: rem(32);

  @media screen and (min-width: 768px) {
    font-size: rem(46);
  }

  @media screen and (min-width: 1200px) {
    font-size: rem(54);
  }

  @media screen and (min-width: 1400px) {
    font-size: rem(72);
  }
}
.solution-box__content__fact {
  position: relative;
  padding: rem(8);
  font-size: rem(16);
  line-height: (26/20);
  min-height: rem(65);
    width: 100%;

    @media screen and (min-width: 768px) {
    padding: rem(8) rem(13) rem(8);
    min-height: rem(100);
    font-size: rem(18);
  }

  @media screen and (min-width: 1400px) {
    font-size: rem(20);
  }
}
.solution-box__content__fact__icon {
  position: absolute;
  right: rem(10);
  top: rem(10);
  font-size: rem(18);
}
.solution-box__content__small-content {
  @media screen and (min-width: 1400px) {
    width: 68%;
  }
}

.solution-box__content--hover {
    color: $default-text-color;

    @media screen and (min-width: 768px) {
        color: #fff;
    }
}
.solution-box__content.solution-box__content--no-height {
    min-height: auto;
}