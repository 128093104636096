/* base form styles in boostrap/_custom.scss */
.formbuilder-form .form-control {
    font-family: $font-default;
    font-weight: $font-default-weight;
    padding: rem(12) rem(10);
    font-size: rem(16);
    line-height: (22/18);
    background: #fff;
    box-shadow: 0 rem(-1) 0 0 $color-grey;
    color: $secondary;

    @media screen and (min-width: 768px){
        padding: rem(14) rem(16);
    }
}
.formbuilder-form .form-control:focus {
    box-shadow: none;
    color: $secondary;
}

.formbuilder-form .has-error .form-control {
    border-color: $color-danger;
}
.formbuilder-form .EditingFormErrorLabel {
    color: $color-danger;
    padding-left: rem(8);
}
.formbuilder-form .has-success .form-control {
    border-color: $color-success;
}
.formbuilder-form .ExplanationText {
    color: $color-grey-dark;
}
.td.EditingFormValueCell {
    margin-right: rem(-8);
    margin-left: rem(-8);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.td.EditingFormValueCell > .editing-form-control-nested-control,
.td.EditingFormValueCell > .ExplanationText {
    padding-left: rem(8);
    padding-right: rem(8);
    position: relative;
    width: 100%;
    min-height: 1px;

    @media screen and (min-width: 768px) {
        flex: 0 0 50%;
        max-width: 50%;
    }
}

.formbuilder-form .tr + .tr {
    margin-top: 1.25rem;
}
.formbuilder-form .EditingFormButtonCell {
    text-align: right;
}

/* checkboxes */
.formbuilder-form .checkbox,
.formbuilder-form .ktc-checkbox {
    cursor: pointer;
    font-size: rem(14);
    line-height: (22/15);
    font-family: $font-default;
    font-weight: $font-default-weight;
}
.formbuilder-form .checkbox + .checkbox,
.formbuilder-form .ktc-checkbox + .ktc-checkbox {
    margin-top: rem(12);
}
.formbuilder-form .checkbox label,
.formbuilder-form .ktc-checkbox label{
    cursor: pointer;
    position: relative;
    padding-left: rem(30);
}
.formbuilder-form .checkbox:before,
.formbuilder-form .ktc-checkbox:before,
.formbuilder-form .checkbox:after,
.formbuilder-form .ktc-checkbox:after {
    content: " ";
    display: table;
}
.formbuilder-form .checkbox:after,
.formbuilder-form .ktc-checkbox:after {
    clear: both;
}
.formbuilder-form .checkbox input,
.formbuilder-form .ktc-checkbox input{
    position: absolute;
    width: rem(1);
    height: rem(1);
    margin: rem(-1);
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}
.formbuilder-form .checkbox label:after,
.formbuilder-form .ktc-checkbox label:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    border: 1px solid #fff;
    background: $color-grey;
    width: rem(22);
    height: rem(22);
    background: #fff;
    box-shadow: 0 -1px 0 0 $color-grey;
}
.formbuilder-form .checkbox label:before,
.formbuilder-form .ktc-checkbox label:before{
    content: $icon-checkmark;
    font-family: iconfont;
    position: absolute;
    top: 50%;
    left: rem(8);
    transform: translateY(-50%);
    font-size: rem(18);
    color: $secondary;
    visibility: hidden;
    z-index: 1;
}

.formbuilder-form .checkbox input:checked ~ label:before,
.formbuilder-form .ktc-checkbox input:checked ~ label:before {
    visibility: visible;
}
.formbuilder-form .checkbox input:not(:checked):focus ~ label:after ,
.formbuilder-form .ktc-checkbox input:not(:checked):focus ~ label:after {
    border-color: $secondary;
}

.formbuilder-form .has-error .checkbox label:after,
.formbuilder-form .has-error .ktc-checkbox label:after{
    border-color: $danger;
}

.formbuilder-form .FieldLabel > .EditingFormLabel {
    font-size: rem(12);
    line-height: (14/12);
    padding-left: rem(8);
    margin-bottom: rem(8);

    @media screen and (min-width: 768px) {
        padding-left: rem(18);
    }
}

/* radios */
.radio-list-vertical {
    margin-top: rem(5);
    display: block;
}
.formbuilder-form .radio,
.formbuilder-form .ktc-radio {
    cursor: pointer;
    font-size: rem(14);
    line-height: (22/15);
    font-family: $font-default;
    font-weight: $font-default-weight;
}
.formbuilder-form .radio label,
.formbuilder-form .ktc-radio label {
    cursor: pointer;
    position: relative;
    margin-bottom: rem(8);
    padding-left: rem(30);
}
.formbuilder-form .radio input[type="radio"],
.formbuilder-form .ktc-radio input[type="radio"] {
    position: absolute;
    width: rem(1);
    height: rem(1);
    margin: rem(-1);
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}
.formbuilder-form .radio label:after,
.formbuilder-form .ktc-radio label:after {
    content: '';
    left: 0;
    top: 0;
    position: absolute;
    overflow: hidden;
    border: rem(1) solid $color-grey;
    background: #fff;
    width: rem(22);
    height: rem(22);
    border-radius: 50%;
}
.formbuilder-form .radio label:before,
.formbuilder-form .ktc-radio label:before {
    content: '';
    position: absolute;
    top: rem(7);
    left: rem(7);
    width: rem(8);
    height: rem(8);
    border-radius: 50%;
    background: $secondary;
    visibility: hidden;
    z-index: 1;
}
.formbuilder-form .radio input:checked + label:before,
.formbuilder-form .ktc-radio input:checked + label:before {
    visibility: visible;
}
.formbuilder-form .radio input:not(:checked):focus + label:after,
.formbuilder-form .ktc-radio input:not(:checked):focus + label:after {
    border-color: $secondary;
}
.formbuilder-form .has-error .radio label:after,
.formbuilder-form .has-error .ktc-radio label:after {
    border-color: $danger;
}

/* form errors */
.formbuilder-form .parsley-errors-list {
    color: $color-danger;
}
.formbuilder-form .parsley-errors-list:empty {
    display: none;
}
.formbuilder-form .parsley-errors-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.formbuilder-form .form-field {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    margin-bottom: 1.25rem;
}
.formbuilder-form .form-field .control-label {
    width: 100%;
}
.formbuilder-form .form-field .editing-form-control-nested-control {
    flex: 0 0 50%;
    max-width: 50%;
}
.formbuilder-form .explanation-text {
    margin-left: rem(16);
}
.formbuilder-form input[type="submit"] {
    display: inline-block;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border: 1px solid $primary;
    border-radius: 0;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    color: #fff;
    background-color: $primary;
    position: relative;
    font-family: $font-default-bold;
    font-weight: $font-default-weight;
    font-size: rem(16);
    line-height: 1.15;
    padding: rem(14) rem(18) rem(13);
    text-transform: uppercase;
    cursor: pointer;

    @media screen and (min-width: 768px) {
        font-size: rem(18);
        padding: rem(16) rem(32) rem(15);
    }
}
.formbuilder-form input[type="submit"]:hover,
.formbuilder-form input[type="submit"]:focus {
    color: #fff;
    background-color: #005f9b;
    border-color: #00588e;
}