.decorator-item {
  position: relative;
  width: 300px;
  height: 173.21px;
  margin: 86.60px 0;
  border-left: solid 1px $color-grey;
  border-right: solid 1px $color-grey;
}

.decorator-item:before,
.decorator-item:after {
  content: "";
  position: absolute;
  z-index: 1;
  width: 212.13px;
  height: 212.13px;
  -webkit-transform: scaleY(0.5774) rotate(-45deg);
  -ms-transform: scaleY(0.5774) rotate(-45deg);
  transform: scaleY(0.5774) rotate(-45deg);
  background-color: inherit;
  left: 42.9340px;
}

.decorator-item:before {
  top: -106.0660px;
  border-top: solid 1.4142px $color-grey;
  border-right: solid 1.4142px $color-grey;
}

.decorator-item:after {
  bottom: -106.0660px;
  border-bottom: solid 1.4142px $color-grey;
  border-left: solid 1.4142px $color-grey;
}