.compare-list {
    font-size: rem(12);

    @media screen and (min-width: 768px) {
        font-size: rem(20);
    }

    @media screen and (max-width: 767px) {
        margin: 0 rem(-15);
    }
}

/* top head bar */
.compare-list__legend {
    padding: rem(10);
    font-size: rem(14);

    @media screen and (min-width: 768px) {
        font-size: rem(18);
        padding: rem(18) rem(20);
    }
}

/* left head bar */
.compare-list__main-head {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
}

.compare-list__main {
    position: relative;
}
.is-slider .compare-list__main{
    @media screen and (min-width: 768px) {
        padding-right: rem(55);
    }
}
/* slider arrow */

/* todo  */
@media screen and (max-width: 767px) {
    .compare-list__main__nav {
        display: none !important;
    }
}

.compare-list__main__nav.slick-arrow {
    position: absolute;
    top: 0;
    right: 0;
    transform: none;
    font-size: rem(18);
    padding: rem(30) rem(10);
    left: auto;
    bottom: 0;
    background: $secondary;
    width: rem(55);
    transition: background 200ms ease-in-out;
}
.compare-list__main__nav.slick-arrow:hover {
    color: #fff;
    background: darken($secondary, 7.5%);
}
.compare-list__main__nav__text {
    position: absolute;
    left: rem(17);
    top: rem(30);
    transform-origin: left top;
    transform: rotate(-90deg) translateX(-100%);
}
.compare-list__main__nav__icon {
    transform: rotate(90deg);
    margin-left: rem(20);
    vertical-align: -0.1em;
}

/* cols */
.compare-list-col {
    padding: rem(10);
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-top: rem(1) solid $color-grey;
    border-right: rem(1) solid $color-grey;
    position: relative;

    @media screen and (min-width: 768px) {
        padding: rem(22) rem(20);
    }
}
.compare-list-col__content {
    max-height: rem(80);
    overflow: hidden;

    @media screen and (min-width: 768px) {
        max-height: rem(85);
    }
}
.is-expanded .compare-list-col__content {
    overflow: visible;
}
.compare-list__main-head .compare-list-col:nth-child(odd),
.compare-list__main .compare-list-col:nth-child(odd) {
    background: $color-grey-light;
}
.compare-list__main-head__col {
    font-size: rem(12);
    word-break: break-word;

    @media screen and (min-width: 768px) {
        font-size: rem(20);
    }
}
.compare-list__main-head__nav {
    flex-grow: 1;

    @media screen and (min-width: 768px) {
        height: 65px;
    }

}
.compare-list__main-head__nav .slick-arrow.compare-list__main-head__nav__btn {
    left: auto;
    right: auto;
    position: relative;
    font-size: rem(14);
    height: 100%;
    transform: none;
    top: 0;
    background: $secondary;
    color: #fff;
    padding: rem(5);

    @media screen and (min-width: 768px) {
        font-size: rem(24);
        height: 65px;
    }
}
.slick-arrow.compare-list__main-head__nav__btn:hover,
.slick-arrow.compare-list__main-head__nav__btn:focus {
    color: #fff;
    background: darken($secondary, 10%);
    outline: none;
}
.slick-arrow.slick-disabled.compare-list__main-head__nav__btn {
    opacity: 1;
    background: $color-grey-medium;
    color: $color-grey-light;
}
/* toggle button  */
.compare-list-col__more {
    position: absolute;
    bottom: rem(5);
    right: rem(5);
    display: none;
    font-size: rem(14);

    @media screen and (min-width: 768px) {
        font-size: rem(20);
        bottom: rem(12);
        right: rem(10);
    }
}
.is-expandable .compare-list-col__more,
.is-expanded .compare-list-col__more {
    display: block;
}
.is-expanded .compare-list-col__more:before {
    content: $icon-minus;
}

/* compare list large cols */
.compare-list--lg {
    @media screen and (min-width: 768px) {
        font-size: rem(17);
        line-height: calc(28/17);
    }
}
.compare-list--lg .compare-list-col:not(.compare-list__main-head__col) {
    justify-content: flex-start;
}
