.lg-backdrop {
    background: $color-grey-light;
}
.lg-actions .lg-next,
.lg-actions .lg-prev {
    background: transparent;
    border: 0;
    border-radius: 0;
    color: $secondary;
    width: rem(30);
    height: rem(50);
    font-size: rem(18);

    @media screen and (min-width: 1200px){
        width: rem(50);
        height: rem(70);
        font-size: rem(30);
    }
}
.lg-actions .lg-next:before,
.lg-actions .lg-prev:after {
    content: none;
}
.lg-actions .lg-prev:hover {
    color: $secondary;
    transform: translateX(rem(-5));
}
.lg-actions .lg-next:hover {
    color: $secondary;
    transform: translateX(rem(5));
}
.lg-sub-html, .lg-toolbar {
    background: transparent;
}
.lg-toolbar {
    background: $color-grey-light;
}
.lg-sub-html {
    background: rgba(0,0,0,.5);
    color: #fff;
    padding: rem(10);
    font-size: rem(14);

    @media screen and (min-width: 768px) {
        font-size: rem(16);
        padding: rem(10) rem(60);
    }
}
#lg-counter {
    color: $color-default;
}
.lg-toolbar .lg-icon {
    color: $color-default;
}
.lg-toolbar .lg-icon:hover {
    color: $secondary;
}
.lg-toolbar .lg-close {
    font-size: rem(16);
    padding: rem(10) 0;
    width: rem(50);

    @media screen and (min-width: 768px) {
        padding: rem(22) 0;
        width: rem(70);
    }
}
.lg-toolbar .lg-close:after {
    content: $icon-close;
    font-family: iconfont;
}
.lightbox-download {
    font-size: rem(13);
}
.lightbox-download:hover {
    text-decoration: underline;
}
.lightbox-download__icon {
    font-size: rem(20);
    margin-right: rem(8);
    vertical-align: -0.3em;
}
/*
.lg-outer .lg-video-cont {
    max-width: 1500px !important; !* overwrite inline style *!
}*/
