.flag {
  display: inline-block;
  background-repeat: no-repeat;
  background-image: url(/static/img/flags/sprite.svg);
}
.flag-worldwide {
    width: 12px;
    height: 12px;
    background-position: 0 0;
    transform: scale(1.5);
}
.flag-se {
    width: 16px;
    height: 12px;
    background-position: -28px 0;
}
.flag-us {
    width: 16px;
    height: 12px;
    background-position: -56px 0;
}
.flag-at {
    width: 16px;
    height: 12px;
    background-position: -84px 0;
}
.flag-bg {
    width: 16px;
    height: 12px;
    background-position: -112px 0;
}
.flag-ch {
    width: 16px;
    height: 12px;
    background-position: -140px 0;
}
.flag-cn {
    width: 16px;
    height: 12px;
    background-position: -168px 0;
}
.flag-cz {
    width: 16px;
    height: 12px;
    background-position: -196px 0;
}
.flag-de {
    width: 16px;
    height: 12px;
    background-position: -224px 0;
}
.flag-es {
    width: 16px;
    height: 12px;
    background-position: -252px 0;
}
.flag-fr {
    width: 16px;
    height: 12px;
    background-position: -280px 0;
}
.flag-gb {
    width: 16px;
    height: 12px;
    background-position: -308px 0;
}
.flag-hr {
    width: 16px;
    height: 12px;
    background-position: -336px 0;
}
.flag-it {
    width: 16px;
    height: 12px;
    background-position: -364px 0;
}
.flag-nl {
    width: 16px;
    height: 12px;
    background-position: -392px 0;
}
.flag-pl {
    width: 16px;
    height: 12px;
    background-position: 0 -28px;
}
.flag-ru {
    width: 16px;
    height: 12px;
    background-position: -28px -28px;
}
.flag-sk {
    width: 16px;
    height: 12px;
    background-position: -56px -28px;
}
.flag-ua {
    width: 16px;
    height: 12px;
    background-position: -84px -28px;
}