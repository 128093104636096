@charset "UTF-8";
/*!
 * Bootstrap v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@import url(./libs/lightgallery.min.css);
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #0077c1;
  --secondary: #5ac4be;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent; }

@-ms-viewport {
  width: device-width; }

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #0077c1;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #004875;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: .5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014 \00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code,
kbd,
pre,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }
  .table .table {
    background-color: #fff; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8d9ee; }

.table-hover .table-primary:hover {
  background-color: #a3cee9; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #a3cee9; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d1eeed; }

.table-hover .table-secondary:hover {
  background-color: #bee7e6; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #bee7e6; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #212529; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #32383e; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #F4F4F4;
  background-clip: padding-box;
  border: 1px solid #F4F4F4;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #42b6ff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 119, 193, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px); }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #F4F4F4; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .input-group-sm > .form-control-plaintext.form-control,
  .input-group-sm > .input-group-prepend > .form-control-plaintext.input-group-text,
  .input-group-sm > .input-group-append > .form-control-plaintext.input-group-text,
  .input-group-sm > .input-group-prepend > .form-control-plaintext.btn,
  .input-group-sm > .input-group-append > .form-control-plaintext.btn, .form-control-plaintext.form-control-lg, .input-group-lg > .form-control-plaintext.form-control,
  .input-group-lg > .input-group-prepend > .form-control-plaintext.input-group-text,
  .input-group-lg > .input-group-append > .form-control-plaintext.input-group-text,
  .input-group-lg > .input-group-prepend > .form-control-plaintext.btn,
  .input-group-lg > .input-group-append > .form-control-plaintext.btn {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm, .input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

select.form-control-sm:not([size]):not([multiple]), .input-group-sm > select.form-control:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: calc(1.8125rem + 2px); }

.form-control-lg, .input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5; }

select.form-control-lg:not([size]):not([multiple]), .input-group-lg > select.form-control:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: calc(2.875rem + 2px); }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745; }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
  .custom-select:valid:focus,
  .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip, .was-validated
  .custom-select:valid ~ .valid-feedback,
  .was-validated
  .custom-select:valid ~ .valid-tooltip,
  .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    background-color: #71dd8a; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }
  .was-validated .custom-file-input:valid ~ .custom-file-label::before, .custom-file-input.is-valid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545; }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
  .custom-select:invalid:focus,
  .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip, .was-validated
  .custom-select:invalid ~ .invalid-feedback,
  .was-validated
  .custom-select:invalid ~ .invalid-tooltip,
  .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    background-color: #efa2a9; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }
  .was-validated .custom-file-input:invalid ~ .custom-file-label::before, .custom-file-input.is-invalid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .btn:hover, .btn:focus {
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 119, 193, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }
  .btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
    background-image: none; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #0077c1;
  border-color: #0077c1; }
  .btn-primary:hover {
    color: #fff;
    background-color: #005f9b;
    border-color: #00588e; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 119, 193, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #0077c1;
    border-color: #0077c1; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #00588e;
    border-color: #005081; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 119, 193, 0.5); }

.btn-secondary {
  color: #212529;
  background-color: #5ac4be;
  border-color: #5ac4be; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #41b6b0;
    border-color: #3eada7; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(90, 196, 190, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #212529;
    background-color: #5ac4be;
    border-color: #5ac4be; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #3eada7;
    border-color: #3ba49e; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(90, 196, 190, 0.5); }

.btn-outline-primary {
  color: #0077c1;
  background-color: transparent;
  background-image: none;
  border-color: #0077c1; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #0077c1;
    border-color: #0077c1; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 119, 193, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #0077c1;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #0077c1;
    border-color: #0077c1; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 119, 193, 0.5); }

.btn-outline-secondary {
  color: #5ac4be;
  background-color: transparent;
  background-image: none;
  border-color: #5ac4be; }
  .btn-outline-secondary:hover {
    color: #212529;
    background-color: #5ac4be;
    border-color: #5ac4be; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(90, 196, 190, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #5ac4be;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #212529;
    background-color: #5ac4be;
    border-color: #5ac4be; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(90, 196, 190, 0.5); }

.btn-link {
  font-weight: 400;
  color: #0077c1;
  background-color: transparent; }
  .btn-link:hover {
    color: #004875;
    text-decoration: underline;
    background-color: transparent;
    border-color: transparent; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    border-color: transparent;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  opacity: 0;
  transition: opacity 0.15s linear; }
  .fade.show {
    opacity: 1; }

.collapse {
  display: none; }
  .collapse.show {
    display: block; }

tr.collapse.show {
  display: table-row; }

tbody.collapse.show {
  display: table-row-group; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }

.dropup,
.dropdown {
  position: relative; }

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent; }

.dropdown-toggle:empty::after {
  margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0.15); }

.dropup .dropdown-menu {
  margin-top: 0;
  margin-bottom: 0; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  margin-top: 0;
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  margin-top: 0;
  margin-right: 0; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #0077c1; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 0 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }
  .btn-group .btn + .btn,
  .btn-group .btn + .btn-group,
  .btn-group .btn-group + .btn,
  .btn-group .btn-group + .btn-group,
  .btn-group-vertical .btn + .btn,
  .btn-group-vertical .btn + .btn-group,
  .btn-group-vertical .btn-group + .btn,
  .btn-group-vertical .btn-group + .btn-group {
    margin-left: -1px; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:first-child {
  margin-left: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after {
    margin-left: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical .btn,
  .btn-group-vertical .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn + .btn,
  .btn-group-vertical > .btn + .btn-group,
  .btn-group-vertical > .btn-group + .btn,
  .btn-group-vertical > .btn-group + .btn-group {
    margin-top: -1px;
    margin-left: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control:focus,
    .input-group > .custom-select:focus,
    .input-group > .custom-file:focus {
      z-index: 3; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    background-color: #0077c1; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 119, 193, 0.25); }
  .custom-control-input:active ~ .custom-control-label::before {
    color: #fff;
    background-color: #75caff; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  margin-bottom: 0; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    user-select: none;
    background-color: #dee2e6; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #0077c1; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #0077c1; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 119, 193, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 119, 193, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #0077c1; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 119, 193, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0;
  appearance: none; }
  .custom-select:focus {
    border-color: #42b6ff;
    outline: 0;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 5px rgba(66, 182, 255, 0.5); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #F4F4F4; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    opacity: 0; }

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%; }

.custom-select-lg {
  height: calc(2.875rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 125%; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-control {
    border-color: #42b6ff;
    box-shadow: 0 0 0 0.2rem rgba(0, 119, 193, 0.25); }
    .custom-file-input:focus ~ .custom-file-control::before {
      border-color: #42b6ff; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(calc(2.25rem + 2px) - 1px * 2);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: 1px solid #ced4da; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d; }

.nav-tabs {
  border-bottom: 0 solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: 0; }
  .nav-tabs .nav-link {
    border: 0 solid transparent; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #5ac4be;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: 0; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #282828;
  background-color: #DCDCDC; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }
  .navbar-toggler:not(:disabled):not(.disabled) {
    cursor: pointer; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; }
    .navbar-expand-sm .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; }
    .navbar-expand-md .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; }
    .navbar-expand-lg .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; }
    .navbar-expand-xl .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .dropdown-menu-right {
      right: 0;
      left: auto; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }
  .navbar-expand .dropup .dropdown-menu {
    top: auto;
    bottom: 100%; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%; }

.card-img-top {
  width: 100%; }

.card-img-bottom {
  width: 100%; }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group > .card {
        flex: 1 0 0%;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 0;
  list-style: none;
  background-color: transparent; }

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  color: #999999;
  content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #999999; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: 0;
  line-height: 1.25;
  color: #0077c1;
  background-color: transparent;
  border: 0 solid transparent; }
  .page-link:hover {
    color: #004875;
    text-decoration: none;
    background-color: transparent;
    border-color: #5ac4be; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: none; }
  .page-link:not(:disabled):not(.disabled) {
    cursor: pointer; }

.page-item:first-child .page-link {
  margin-left: 0; }

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: transparent;
  border-color: #5ac4be; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: transparent;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em; }

.badge-primary {
  color: #fff;
  background-color: #0077c1; }
  .badge-primary[href]:hover, .badge-primary[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #00588e; }

.badge-secondary {
  color: #212529;
  background-color: #5ac4be; }
  .badge-secondary[href]:hover, .badge-secondary[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #3eada7; }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #003e64;
  background-color: #cce4f3;
  border-color: #b8d9ee; }
  .alert-primary hr {
    border-top-color: #a3cee9; }
  .alert-primary .alert-link {
    color: #001e31; }

.alert-secondary {
  color: #2f6663;
  background-color: #def3f2;
  border-color: #d1eeed; }
  .alert-secondary hr {
    border-top-color: #bee7e6; }
  .alert-secondary .alert-link {
    color: #1f4341; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  background-color: #0077c1;
  transition: width 0.6s ease; }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:last-child {
    margin-bottom: 0; }
  .list-group-item:hover, .list-group-item:focus {
    z-index: 1;
    text-decoration: none; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #0077c1;
    border-color: #0077c1; }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0; }

.list-group-item-primary {
  color: #003e64;
  background-color: #b8d9ee; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #003e64;
    background-color: #a3cee9; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #003e64;
    border-color: #003e64; }

.list-group-item-secondary {
  color: #2f6663;
  background-color: #d1eeed; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #2f6663;
    background-color: #bee7e6; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #2f6663;
    border-color: #2f6663; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover, .close:focus {
    color: #000;
    text-decoration: none;
    opacity: .75; }
  .close:not(:disabled):not(.disabled) {
    cursor: pointer; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none; }

.modal-open {
  overflow: hidden; }

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -25%); }
  .modal.show .modal-dialog {
    transform: translate(0, 0); }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2)); }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid transparent;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef; }
  .modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2)); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2); }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top .arrow, .bs-popover-auto[x-placement^="top"] .arrow {
    bottom: calc((0.5rem + 1px) * -1); }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before,
  .bs-popover-top .arrow::after,
  .bs-popover-auto[x-placement^="top"] .arrow::after {
    border-width: 0.5rem 0.5rem 0; }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before {
    bottom: 0;
    border-top-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-top .arrow::after,
  .bs-popover-auto[x-placement^="top"] .arrow::after {
    bottom: 1px;
    border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right .arrow, .bs-popover-auto[x-placement^="right"] .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before,
  .bs-popover-right .arrow::after,
  .bs-popover-auto[x-placement^="right"] .arrow::after {
    border-width: 0.5rem 0.5rem 0.5rem 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before {
    left: 0;
    border-right-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-right .arrow::after,
  .bs-popover-auto[x-placement^="right"] .arrow::after {
    left: 1px;
    border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom .arrow, .bs-popover-auto[x-placement^="bottom"] .arrow {
    top: calc((0.5rem + 1px) * -1); }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before,
  .bs-popover-bottom .arrow::after,
  .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-width: 0 0.5rem 0.5rem 0.5rem; }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before {
    top: 0;
    border-bottom-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-bottom .arrow::after,
  .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    top: 1px;
    border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left .arrow, .bs-popover-auto[x-placement^="left"] .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before,
  .bs-popover-left .arrow::after,
  .bs-popover-auto[x-placement^="left"] .arrow::after {
    border-width: 0.5rem 0 0.5rem 0.5rem; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before {
    right: 0;
    border-left-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-left .arrow::after,
  .bs-popover-auto[x-placement^="left"] .arrow::after {
    right: 1px;
    border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb; }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }

.carousel-item {
  position: relative;
  display: none;
  align-items: center;
  width: 100%;
  transition: transform 0.6s ease;
  backface-visibility: hidden;
  perspective: 1000px; }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0; }

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  transform: translateX(0); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-next.carousel-item-left,
    .carousel-item-prev.carousel-item-right {
      transform: translate3d(0, 0, 0); } }

.carousel-item-next,
.active.carousel-item-right {
  transform: translateX(100%); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-next,
    .active.carousel-item-right {
      transform: translate3d(100%, 0, 0); } }

.carousel-item-prev,
.active.carousel-item-left {
  transform: translateX(-100%); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-prev,
    .active.carousel-item-left {
      transform: translate3d(-100%, 0, 0); } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5; }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    position: relative;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    background-color: rgba(255, 255, 255, 0.5); }
    .carousel-indicators li::before {
      position: absolute;
      top: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
    .carousel-indicators li::after {
      position: absolute;
      bottom: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
  .carousel-indicators .active {
    background-color: #fff; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #0077c1 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #00588e !important; }

.bg-secondary {
  background-color: #5ac4be !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #3eada7 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #0077c1 !important; }

.border-secondary {
  border-color: #5ac4be !important; }

.border-white {
  border-color: #fff !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.embed-responsive-2by1::before {
  padding-top: 50%; }

.embed-responsive-technogramm::before {
  padding-top: 93.75%; }

.embed-responsive-8by3::before {
  padding-top: 37.5%; }

.embed-responsive-7by3::before {
  padding-top: 42.85714%; }

@media screen and (max-width: 767px) {
  .embed-responsive-5by6-xs::before {
    padding-top: 120%; }
  .embed-responsive-8by5-xs::before {
    padding-top: 62.5%; }
  .embed-responsive-1by1-xs::before {
    padding-top: 100%; } }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-justify {
  text-align: justify !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #0077c1 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #00588e !important; }

.text-secondary {
  color: #5ac4be !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #3eada7 !important; }

.text-muted {
  color: #6c757d !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #ddd !important; } }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Global variables and settings */
/* ordered list of all media queries (for mqpacker) ### INFO: empty comments are needed ### */
@media screen and (max-width: 767px) {
  /**/ }

@media screen and (min-width: 768px) {
  /**/ }

@media screen and (min-width: 992px) {
  /**/ }

@media screen and (min-width: 1200px) {
  /**/ }

@media screen and (min-width: 1400px) {
  /**/ }

/* colors */
/* fonts */
/*$font-fira-mono:        'FiraMono-Regular', sans-serif !default;*/
@font-face {
  font-family: "iconfont";
  src: url("/static/build/icons/font/iconfont.eot?#iefix") format("eot"), url("/static/build/icons/font/iconfont.woff") format("woff"), url("/static/build/icons/font/iconfont.ttf") format("ttf");
  font-weight: normal;
  font-style: normal; }

.icon {
  display: inline-block;
  font-family: "iconfont";
  speak: none;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0); }

.icon-lg {
  font-size: 1.3333333333333333em;
  line-height: 0.75em;
  vertical-align: -15%; }

.icon-2x {
  font-size: 2em; }

.icon-3x {
  font-size: 3em; }

.icon-4x {
  font-size: 4em; }

.icon-5x {
  font-size: 5em; }

.icon-fw {
  width: 1.2857142857142858em;
  text-align: center; }

.icon-ul {
  padding-left: 0;
  margin-left: 2.14285714em;
  list-style-type: none; }

.icon-ul > li {
  position: relative; }

.icon-li {
  position: absolute;
  left: -2.14285714em;
  width: 2.14285714em;
  top: 0.14285714em;
  text-align: center; }

.icon-li.icon-lg {
  left: -1.85714286em; }

.icon-rotate-90 {
  transform: rotate(90deg); }

.icon-rotate-180 {
  transform: rotate(180deg); }

.icon-rotate-270 {
  transform: rotate(270deg); }

.icon-flip-horizontal {
  transform: scale(-1, 1); }

.icon-flip-vertical {
  transform: scale(1, -1); }

:root .icon-rotate-90,
:root .icon-rotate-180,
:root .icon-rotate-270,
:root .icon-flip-horizontal,
:root .icon-flip-vertical {
  filter: none; }

.icon-agenti:before {
  content: "\EA01"; }

.icon-ansicht:before {
  content: "\EA02"; }

.icon-arrow-down:before {
  content: "\EA03"; }

.icon-arrow-left:before {
  content: "\EA04"; }

.icon-arrow-right:before {
  content: "\EA05"; }

.icon-chat:before {
  content: "\EA06"; }

.icon-checkmark:before {
  content: "\EA07"; }

.icon-close:before {
  content: "\EA08"; }

.icon-compass:before {
  content: "\EA09"; }

.icon-download:before {
  content: "\EA0A"; }

.icon-elements:before {
  content: "\EA0B"; }

.icon-expand:before {
  content: "\EA0C"; }

.icon-extranet:before {
  content: "\EA0D"; }

.icon-facebook:before {
  content: "\EA0E"; }

.icon-fax:before {
  content: "\EA0F"; }

.icon-feather:before {
  content: "\EA10"; }

.icon-hexagon:before {
  content: "\EA11"; }

.icon-homepage:before {
  content: "\EA12"; }

.icon-instagram:before {
  content: "\EA13"; }

.icon-linkedin:before {
  content: "\EA14"; }

.icon-listview:before {
  content: "\EA15"; }

.icon-lock:before {
  content: "\EA16"; }

.icon-mail-round:before {
  content: "\EA17"; }

.icon-mail:before {
  content: "\EA18"; }

.icon-map:before {
  content: "\EA19"; }

.icon-minus:before {
  content: "\EA1A"; }

.icon-news:before {
  content: "\EA1B"; }

.icon-open:before {
  content: "\EA1C"; }

.icon-person:before {
  content: "\EA1D"; }

.icon-phone-round:before {
  content: "\EA1E"; }

.icon-phone:before {
  content: "\EA1F"; }

.icon-pinterest:before {
  content: "\EA20"; }

.icon-play:before {
  content: "\EA21"; }

.icon-poi:before {
  content: "\EA22"; }

.icon-quote:before {
  content: "\EA23"; }

.icon-scroll-down:before {
  content: "\EA24"; }

.icon-scroll-right:before {
  content: "\EA25"; }

.icon-search:before {
  content: "\EA26"; }

.icon-technogramm:before {
  content: "\EA27"; }

.icon-twitter:before {
  content: "\EA28"; }

.icon-vk:before {
  content: "\EA29"; }

.icon-world:before {
  content: "\EA2A"; }

.icon-xing:before {
  content: "\EA2B"; }

.icon-youtube:before {
  content: "\EA2C"; }

.flag {
  display: inline-block;
  background-repeat: no-repeat;
  background-image: url(/static/img/flags/sprite.svg); }

.flag-worldwide {
  width: 12px;
  height: 12px;
  background-position: 0 0;
  transform: scale(1.5); }

.flag-se {
  width: 16px;
  height: 12px;
  background-position: -28px 0; }

.flag-us {
  width: 16px;
  height: 12px;
  background-position: -56px 0; }

.flag-at {
  width: 16px;
  height: 12px;
  background-position: -84px 0; }

.flag-bg {
  width: 16px;
  height: 12px;
  background-position: -112px 0; }

.flag-ch {
  width: 16px;
  height: 12px;
  background-position: -140px 0; }

.flag-cn {
  width: 16px;
  height: 12px;
  background-position: -168px 0; }

.flag-cz {
  width: 16px;
  height: 12px;
  background-position: -196px 0; }

.flag-de {
  width: 16px;
  height: 12px;
  background-position: -224px 0; }

.flag-es {
  width: 16px;
  height: 12px;
  background-position: -252px 0; }

.flag-fr {
  width: 16px;
  height: 12px;
  background-position: -280px 0; }

.flag-gb {
  width: 16px;
  height: 12px;
  background-position: -308px 0; }

.flag-hr {
  width: 16px;
  height: 12px;
  background-position: -336px 0; }

.flag-it {
  width: 16px;
  height: 12px;
  background-position: -364px 0; }

.flag-nl {
  width: 16px;
  height: 12px;
  background-position: -392px 0; }

.flag-pl {
  width: 16px;
  height: 12px;
  background-position: 0 -28px; }

.flag-ru {
  width: 16px;
  height: 12px;
  background-position: -28px -28px; }

.flag-sk {
  width: 16px;
  height: 12px;
  background-position: -56px -28px; }

.flag-ua {
  width: 16px;
  height: 12px;
  background-position: -84px -28px; }

/* General base styling & reset (mostly plain element styling; very low specificity ) */
/*Base-Styling for HTML-Elements*/
a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none; }

th {
  font-family: "EncodeSans-Semibold", "Arial Black", sans-serif;
  font-weight: 400; }

textarea {
  resize: vertical; }

label {
  font-weight: normal;
  margin-bottom: 0; }

ul,
ol,
dl {
  margin-bottom: 0; }

dt {
  font-weight: normal; }

*::-moz-selection {
  color: #ffffff;
  background-color: #0077c1; }

*::selection {
  color: #ffffff;
  background-color: #0077c1; }

html {
  -ms-overflow-style: scrollbar;
  /*prevent scrollbar from overlapping content in IE*/ }

body {
  position: relative;
  overflow-x: hidden; }

body.has-cookie-bar {
  margin-bottom: 5rem; }
  @media screen and (max-width: 767px) {
    body.has-cookie-bar {
      margin-bottom: 9.375rem; } }

@media screen and (min-width: 768px) and (max-width: 991px) {
  body {
    width: calc(982rem/16);
    overflow-x: scroll; } }

@media screen and (max-width: 767px) {
  body {
    min-width: calc(320rem/16);
    max-width: 100%; } }

.container.container {
  max-width: calc(1470rem/16);
  width: 100%; }

.container-narrow {
  max-width: 80rem; }

.container-sm {
  max-width: 66.25rem; }

.container-xs {
  max-width: 60rem;
  margin: 0 auto; }

body {
  color: #282828;
  font-family: "EncodeSans-Light", Arial, sans-serif;
  font-size: 0.9375rem;
  line-height: 1.64706; }
  @media screen and (min-width: 768px) {
    body {
      font-size: 1.0625rem; } }

b,
strong,
.strong,
.font-default-bold {
  font-family: "EncodeSans-Semibold", "Arial Black", sans-serif;
  font-weight: 400; }

.font-default-italic {
  font-family: "EncodeSans-Light", Arial, sans-serif;
  font-weight: 400; }

/*.font-fira-mono {
    font-family: $font-fira-mono;
}*/
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-top: 0;
  display: block; }

h1, .h1 {
  font-size: 4.5rem;
  line-height: 1;
  font-family: "EncodeSans-Semibold", "Arial Black", sans-serif;
  font-weight: 400; }

h2, .h2 {
  font-size: 1.875rem;
  line-height: 1.21739;
  font-family: "EncodeSans-Semibold", "Arial Black", sans-serif;
  font-weight: 400; }
  @media screen and (min-width: 768px) {
    h2, .h2 {
      font-size: 2.875rem; } }

h3, .h3 {
  font-size: 1.5rem;
  font-family: "EncodeSans-Semibold", "Arial Black", sans-serif;
  font-weight: 400; }
  @media screen and (min-width: 768px) {
    h3, .h3 {
      font-size: 2rem; } }

h4, .h4 {
  font-size: 1.125rem; }
  @media screen and (min-width: 768px) {
    h4, .h4 {
      font-size: 1.375rem; } }

h5, .h5 {
  font-size: calc(18rem/16); }

h6, .h6 {
  font-size: 1em; }

/* Navbar */
.navbar {
  padding: 0;
  align-items: flex-end;
  z-index: 100;
  background: #fff;
  box-shadow: 0 0.125rem 0.1875rem rgba(0, 0, 0, 0.1); }

.navbar-brand {
  width: 3.75rem;
  margin: -1.5625rem 2.25rem -1.5rem -0.1875rem;
  z-index: 1;
  padding: 0; }
  @media screen and (min-width: 768px) {
    .navbar-brand {
      width: 6.25rem;
      margin: -4.0625rem 1.5625rem -4.0625rem -0.1875rem; } }
  @media screen and (min-width: 1200px) {
    .navbar-brand {
      width: 6.5625rem;
      margin: -3.75rem 2.25rem -3.5rem -0.1875rem; } }

.navbar-brand > img {
  max-width: 100%;
  height: auto; }

.navbar-nav .nav-link:hover {
  color: #5ac4be; }

/* navbar main */
.navbar-main {
  border-top: 0.0625rem solid #DCDCDC; }

@media screen and (min-width: 768px) {
  .navbar-main .nav-item {
    margin-top: calc(15rem / 16); } }

@media screen and (min-width: 768px) {
  .navbar-main .navbar-nav {
    margin: 0 -1.125rem; } }

.navbar-main .nav-item .nav-link {
  font-family: "EncodeSans-Semibold", "Arial Black", sans-serif;
  font-weight: 400;
  font-size: calc(16rem / 16);
  line-height: calc(21/18);
  padding: calc(20rem / 16); }
  @media screen and (min-width: 768px) {
    .navbar-main .nav-item .nav-link {
      padding: calc(10rem / 16) calc(12rem / 16) calc(24rem / 16); } }
  @media screen and (min-width: 1200px) {
    .navbar-main .nav-item .nav-link {
      padding: calc(10rem / 16) calc(18rem / 16) calc(24rem / 16);
      font-size: calc(18rem / 16); } }

/* navbar top */
.navbar-top {
  margin: 0 0 0 -0.5625rem;
  font-size: 0.8125rem; }
  @media screen and (min-width: 1200px) {
    .navbar-top {
      font-size: 1rem; } }

.navbar-top .nav-item {
  padding: 0.5rem 0; }

.navbar-top .nav-item .nav-link {
  font-family: "EncodeSans-Light", Arial, sans-serif;
  font-weight: 400; }

/* navbar search */
.navbar-main__search {
  flex-wrap: nowrap; }

.navbar-main__search .form-control {
  border: none;
  background: transparent;
  font-family: "EncodeSans-Semibold", "Arial Black", sans-serif;
  font-weight: 400;
  padding-right: 0.9375rem;
  font-size: 1rem;
  display: inline-block;
  vertical-align: middle; }
  @media screen and (min-width: 768px) {
    .navbar-main__search .form-control {
      width: auto; } }

.navbar-main__search__submit__icon {
  font-size: 1.25rem;
  vertical-align: -0.2em; }

/* navbar shortcuts*/
@media screen and (min-width: 1400px) {
  li + li .navbar-top__link {
    margin-left: 0.625rem; } }

.navbar-top__link__icon {
  vertical-align: -.05em;
  margin-right: 0.1875rem; }

.navbar-top__link__icon-flag {
  margin-right: 0.5rem; }

/* affix */
.navbar.is-transitioning {
  position: absolute;
  left: 0;
  right: 0; }

.navbar.is-affix {
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0; }

.nav-item__dropdown {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #3B3B39;
  color: #fff;
  padding: 1.875rem 0.9375rem; }
  @media screen and (min-width: 768px) {
    .nav-item__dropdown {
      bottom: auto;
      display: none;
      top: 100%;
      padding: 1.5625rem 0.625rem 0rem; } }

.nav-item__dropdown.dropdown-lg {
  padding-bottom: 5rem;
  background-image: url(/static/img/flexigons/flexigon-white-square.svg);
  background-repeat: no-repeat;
  background-position: -455px -35px;
  background-size: 1000px 1000px; }

.nav-item__dropdown.dropdown-lg li a {
  padding: 0 0 1rem; }

@media screen and (min-width: 768px) {
  .nav-item__dropdown-container {
    max-width: calc(1470rem/16);
    padding-left: 8.25rem;
    margin-left: auto;
    margin-right: auto; } }

@media screen and (min-width: 1200px) {
  .nav-item__dropdown-container {
    padding-left: 10.9375rem; } }

.list-styled {
  padding-left: 1.875rem; }

.list-styled li:before {
  content: '';
  border-bottom: solid 1px;
  border-right: solid 1px;
  border-color: #696969;
  position: absolute;
  margin-top: 5px;
  margin-left: -20px;
  width: 9px;
  height: 9px;
  transform: translateY(51%) rotate(-45deg); }

.nav-item.is-open .nav-item__dropdown {
  display: block; }

@media screen and (min-width: 768px) {
  .nav-item:hover .nav-item__dropdown,
  .nav-link:hover ~ .nav-item__dropdown {
    display: block; }
  .nav-item__dropdown li {
    margin-left: -0.25rem;
    display: inline-block; }
  .nav-item__dropdown li a {
    font-size: 0.875rem;
    padding: 0 1rem 1.5rem;
    display: inline-block;
    line-height: 1.2; }
  /*.nav-item__dropdown li:first-child  > a{
        padding-left: rem(4);
    }*/ }

.nav-item__dropdown li:hover > a,
.nav-item__dropdown li a:hover {
  color: #5ac4be; }

/* dropdown subnav */
.nav-item__dropdown__subnav {
  display: none; }
  @media screen and (min-width: 768px) {
    .nav-item__dropdown__subnav {
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      margin-top: -0.0625rem;
      background-color: #3B3B39; } }

.nav-item__dropdown__subnav ul {
  padding-bottom: 0.3125rem; }
  @media screen and (min-width: 768px) {
    .nav-item__dropdown__subnav ul {
      border-top: 0.125rem solid #565656;
      padding: 0.875rem 0 1.375rem; } }

.nav-item__dropdown__subnav li a {
  padding-bottom: 0;
  text-transform: none; }
  @media screen and (min-width: 768px) {
    .nav-item__dropdown__subnav li a {
      text-transform: uppercase; } }

@media screen and (min-width: 768px) {
  .nav-item__dropdown li:hover > .nav-item__dropdown__subnav {
    display: block; } }

@media screen and (max-width: 767px) {
  html.has-overflow-hidden, .has-overflow-hidden body {
    overflow: hidden; }
  .navbar-collapse {
    position: absolute;
    height: calc(100vh - 60px);
    background: #fff;
    color: #282828;
    left: 0;
    right: 0;
    top: 3.75rem;
    transform: translateX(-100%);
    transition: transform 250ms ease-in-out;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 6.25rem; }
  .is-open.navbar-main {
    background: #fff; }
  .is-open .navbar-collapse {
    transform: translateX(0); }
  .is-open .navbar__icon-link {
    color: #282828; }
  .navbar-main {
    flex-direction: row;
    justify-content: start; }
  .navbar-nav {
    display: block;
    width: 100%;
    margin: 0;
    padding: 0; }
  .navbar-nav > li {
    border-bottom: 0.0625rem solid #DCDCDC; }
  .navbar-nav li a {
    display: block;
    position: relative;
    padding: 0.625rem 0.3125rem; }
  .nav-item__dropdown__subnav li a {
    padding: 0.25rem 0.9375rem; }
  .navbar-nav li.is-open > a {
    color: #5ac4be; }
  /* toggle button */
  .navbar-toggler {
    width: 3.75rem;
    height: 3.75rem;
    text-align: center;
    font-size: 0;
    position: relative; }
  .navbar-toggler__bar {
    display: inline-block;
    width: 1.5625rem;
    height: 0.125rem;
    background: #3B3B39;
    transition: transform 250ms ease-in-out, opacity 250ms ease-in-out, color 250ms ease-in-out; }
  .navbar-main--white .navbar-toggler__bar {
    background: #3B3B39; }
  .navbar-toggler__bar + .navbar-toggler__bar {
    margin-top: 0.3125rem; }
  .is-open .navbar-toggler__bar {
    background: #3B3B39; }
  .is-open .navbar-toggler__bar:nth-child(2) {
    opacity: 0; }
  .is-open .navbar-toggler__bar:nth-child(1) {
    transform: rotate(-45deg) translateX(-0.25rem) translateY(0.25rem); }
  .is-open .navbar-toggler__bar:nth-child(3) {
    transform: rotate(45deg) translateX(-0.375rem) translateY(-0.3125rem); }
  .nav-item__toggle {
    position: absolute;
    padding: 0.9375rem;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1rem;
    transition: transform 250ms ease-in-out; }
  .nav-item__dropdown li.is-open > a .nav-item__toggle {
    transform: translateY(-50%) rotate(-180deg); }
  .nav-item__dropdown {
    transform: translateX(100%);
    transition: transform 200ms ease-in-out;
    z-index: 1;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    background-image: url(/static/img/flexigons/flexigon-white-square.svg);
    background-repeat: no-repeat;
    background-position: -105px 100%;
    background-size: 500px 500px; }
  .is-open > .nav-item__dropdown {
    transform: translateX(0); }
  .is-open > .nav-item__dropdown__subnav {
    display: block; }
  .nav-item__dropdown__back {
    width: 100%;
    padding: 0.3125rem 0;
    font-size: 1rem;
    font-family: "EncodeSans-Semibold", "Arial Black", sans-serif;
    font-weight: 400; }
  .nav-item__dropdown__back__icon {
    margin-right: 0.625rem;
    font-size: 0.75rem; }
  /* navbar brand */
  .navbar-brand {
    padding-left: 0.9375rem;
    max-width: 5rem; }
  .navbar-nav--sm {
    margin-top: 0.9375rem; }
  .navbar-nav--sm li {
    border: 0; }
  .navbar-main .navbar-nav--sm .nav-item > a {
    font-size: 0.75rem;
    padding: 0.5rem 1.25rem; }
  .navbar-main__search {
    padding: 0 0.5rem; } }

.breadcrumb {
  padding: 0.9375rem 1rem 0.625rem 0.375rem; }
  @media screen and (min-width: 768px) {
    .breadcrumb {
      padding: 2.5rem 1rem 1.75rem 0.375rem; } }
  @media screen and (min-width: 1200px) {
    .breadcrumb {
      padding: 1.375rem 1rem 1.75rem 0.375rem; } }

.breadcrumb-item {
  color: #0077c1;
  font-size: 0.875rem;
  line-height: 1.14286;
  position: relative; }

.breadcrumb-item:not(:last-child) {
  margin-right: 0.625rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.875rem; }

.breadcrumb-item + .breadcrumb-item::before {
  content: '';
  width: 0.1875rem;
  height: 0.1875rem;
  background: #999;
  display: inline-block;
  padding: 0;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%); }

.scrollspy {
  background: #0077c1;
  color: #fff;
  width: 35px;
  position: fixed;
  left: 0;
  top: 36%;
  z-index: 10;
  border-radius: 6px; }

.scrollspy:before,
.scrollspy:after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 24px 0 24px 35px;
  border-color: transparent transparent transparent #0077c1;
  position: absolute;
  top: -22px;
  left: -1px; }

.scrollspy:after {
  top: auto;
  bottom: -22px; }

.scrollspy li + li {
  margin-top: 23px; }

.scrollspy__item {
  position: relative;
  padding: 0 5px;
  font-size: 12px;
  text-align: center;
  z-index: 1;
  font-family: "EncodeSans-Semibold", "Arial Black", sans-serif;
  font-weight: 400;
  opacity: .5;
  transition: opacity 200ms ease-in-out; }

.scrollspy__item.active {
  opacity: 1; }

.scrollspy__item__text {
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translateY(-50%) translateX(4px);
  background: #5ac4be;
  font-family: "EncodeSans-Semibold", "Arial Black", sans-serif;
  font-weight: 400;
  opacity: 0;
  transition: opacity 200ms ease-in-out;
  padding: 2px 8px;
  line-height: 1.2; }

.active .scrollspy__item__text,
.scrollspy__item:hover .scrollspy__item__text {
  opacity: 1; }

/* Generic helper classes (mostly layout) */
.row.row--gutter-width-0 {
  margin-left: 0;
  margin-right: 0; }

.row--gutter-width-0 > .col,
.row--gutter-width-0 > [class^="col"],
.row--gutter-width-0 > [class*=" col"] {
  padding-left: 0;
  padding-right: 0; }

.row.row--gutter-width-4 {
  margin-left: -0.125rem;
  margin-right: -0.125rem; }

.row--gutter-width-4 > .col,
.row--gutter-width-4 > [class^="col"],
.row--gutter-width-4 > [class*=" col"] {
  padding-left: 0.125rem;
  padding-right: 0.125rem; }

.row.row--gutter-width-12 {
  margin-left: -0.375rem;
  margin-right: -0.375rem; }

.row--gutter-width-12 > .col,
.row--gutter-width-12 > [class^="col"],
.row--gutter-width-12 > [class*=" col"] {
  padding-left: 0.375rem;
  padding-right: 0.375rem; }

.row.row--gutter-width-16 {
  margin-left: -0.5rem;
  margin-right: -0.5rem; }

.row--gutter-width-16 > .col,
.row--gutter-width-16 > [class^="col"],
.row--gutter-width-16 > [class*=" col"] {
  padding-left: 0.5rem;
  padding-right: 0.5rem; }

.row.row--gutter-width-20 {
  margin-left: -10px;
  margin-right: -10px; }

.row--gutter-width-20 > .col,
.row--gutter-width-20 > [class^="col"],
.row--gutter-width-20 > [class*=" col"] {
  padding-left: 10px;
  padding-right: 10px; }

@media screen and (min-width: 1200px) {
  .row.row--gutter-width-36 {
    margin-left: -18px;
    margin-right: -18px; }
  .row--gutter-width-36 > .col,
  .row--gutter-width-36 > [class^="col"],
  .row--gutter-width-36 > [class*=" col"] {
    padding-left: 18px;
    padding-right: 18px; }
  .row.row--gutter-width-60 {
    margin-left: -30px;
    margin-right: -30px; }
  .row--gutter-width-60 > .col,
  .row--gutter-width-60 > [class^="col"],
  .row--gutter-width-60 > [class*=" col"] {
    padding-left: 30px;
    padding-right: 30px; }
  .row.row--gutter-width-120 {
    margin-left: -60px;
    margin-right: -60px; }
  .row--gutter-width-120 > .col,
  .row--gutter-width-120 > [class^="col"],
  .row--gutter-width-120 > [class*=" col"] {
    padding-left: 60px;
    padding-right: 60px; } }

/* vertical gutter */
.row--vertical-gutter-4 {
  margin-top: -0.25rem; }

.row--vertical-gutter-4 > .col,
.row--vertical-gutter-4 > [class*='col-'] {
  margin-top: 0.25rem; }

.row--vertical-gutter-6 {
  margin-top: -0.375rem; }

.row--vertical-gutter-6 > .col,
.row--vertical-gutter-6 > [class*='col-'] {
  margin-top: 0.375rem; }

.row--vertical-gutter-12 {
  margin-top: -0.75rem; }

.row--vertical-gutter-12 > .col,
.row--vertical-gutter-12 > [class*='col-'] {
  margin-top: 0.75rem; }

.row--vertical-gutter-24 {
  margin-top: -1.5rem; }

.row--vertical-gutter-24 > .col,
.row--vertical-gutter-24 > [class*='col-'] {
  margin-top: 1.5rem; }

.row--vertical-gutter-30 {
  margin-top: -1.875rem; }

.row--vertical-gutter-30 > .col,
.row--vertical-gutter-30 > [class*='col-'] {
  margin-top: 1.875rem; }

@media screen and (min-width: 768px) {
  .row--vertical-gutter-70-lg {
    margin-top: -4.375rem; }
  .row--vertical-gutter-70-lg > .col,
  .row--vertical-gutter-70-lg > [class*='col-'] {
    margin-top: 4.375rem; } }

/* Components */
.btn {
  position: relative;
  font-family: "EncodeSans-Semibold", "Arial Black", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.15;
  padding: 0.875rem 1.125rem 0.8125rem;
  text-transform: uppercase; }
  @media screen and (min-width: 768px) {
    .btn {
      font-size: 1.125rem;
      padding: 1rem 2rem 0.9375rem; } }

.btn-sm, .btn-group-sm > .btn {
  display: inline-block;
  padding: .5rem !important;
  font-family: "EncodeSans-Semibold", "Arial Black", sans-serif;
  font-size: 0.9375rem !important;
  text-transform: uppercase; }

.btn-secondary {
  margin-right: 0.9375rem;
  color: #fff; }

.btn-secondary:after {
  content: '';
  position: absolute;
  right: -0.0625rem;
  bottom: -0.0625rem;
  top: -0.0625rem;
  transform: translateX(0.9375rem);
  width: 0.75rem;
  background: #0077c1; }

.btn-grey-light {
  background: #f4f4f4;
  color: #282828; }

.btn-grey-light:hover,
.btn-grey-light:focus {
  background: #DCDCDC; }

.btn-no-styling,
.btn.btn-no-styling {
  border: none;
  background: none;
  text-align: left;
  padding: 0;
  color: inherit;
  font-family: inherit;
  font-weight: inherit;
  cursor: pointer; }

/* button with icon & text */
.btn.btn-with-icon {
  padding-left: 3.75rem; }

.btn-with-icon .btn__icon {
  position: absolute;
  left: 0.9375rem;
  top: 50%;
  transform: translateY(-50%);
  font-size: 2rem; }

/* disabled buttons */
.btn.disabled {
  background-color: #999999;
  border-color: #999999; }

.btn-secondary.disabled:after {
  background-color: #696969; }

.icon-btn {
  position: relative;
  width: 50px;
  height: 50px;
  padding: 5px; }

.icon-btn__icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%); }

.icon-btn__icon.icon-rotate-180 {
  transform: translateX(-50%) translateY(-50%) rotate(180deg); }

.custom-checkbox {
  cursor: pointer;
  font-size: 0.875rem;
  line-height: 1.46667;
  font-family: "EncodeSans-Light", Arial, sans-serif;
  font-weight: 400; }

.custom-checkbox + .custom-checkbox {
  margin-top: 0.75rem; }

.custom-checkbox label {
  cursor: pointer; }

.custom-checkbox:before,
.custom-checkbox:after {
  content: " ";
  display: table; }

.custom-checkbox:after {
  clear: both; }

.custom-checkbox__input {
  position: absolute;
  width: 0.0625rem;
  height: 0.0625rem;
  margin: -0.0625rem;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.custom-checkbox__box {
  position: relative;
  float: left;
  margin-right: 0.75rem;
  border: 1px solid #DCDCDC;
  background: #DCDCDC;
  width: 1.375rem;
  height: 1.375rem;
  margin-top: -0.0625rem; }

.custom-checkbox__box:before {
  position: absolute;
  top: 50%;
  right: -0.3125rem;
  transform: translateY(-50%);
  font-size: 1.125rem;
  color: #5ac4be;
  visibility: hidden; }

.custom-checkbox__input:checked ~ .custom-checkbox__box:before {
  visibility: visible; }

.custom-checkbox__input:not(:checked):focus ~ .custom-checkbox__box {
  border-color: #5ac4be; }

.has-error .custom-checkbox__box {
  border-color: #dc3545; }

.custom-checkbox__text {
  display: block;
  overflow: hidden; }

.custom-checkbox__text__icon {
  font-size: 1.25rem;
  vertical-align: -.2em;
  margin-left: 0.125rem; }

.custom-checkbox--white .custom-checkbox__box {
  background: #fff;
  border-color: #fff;
  box-shadow: 0 -1px 0 0 #DCDCDC; }

.custom-radio {
  cursor: pointer;
  font-size: 0.875rem;
  line-height: 1.46667;
  font-family: "EncodeSans-Light", Arial, sans-serif;
  font-weight: 400; }

.custom-radio + .custom-radio {
  margin-top: 0.75rem; }

.custom-radio:before,
.custom-radio:after {
  content: " ";
  display: table; }

.custom-radio:after {
  clear: both; }

.custom-radio__input {
  position: absolute;
  width: 0.0625rem;
  height: 0.0625rem;
  margin: -0.0625rem;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.custom-radio__box {
  position: relative;
  float: left;
  margin-right: 0.75rem;
  overflow: hidden;
  border: 0.0625rem solid #DCDCDC;
  background: #DCDCDC;
  width: 1.375rem;
  height: 1.375rem;
  border-radius: 50%; }

.custom-radio__box:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background: #5ac4be;
  visibility: hidden; }

.custom-radio__input:checked ~ .custom-radio__box:before {
  visibility: visible; }

.custom-checkbox__input:not(:checked):focus ~ .custom-radio__box {
  border-color: #0077c1; }

.has-error .custom-radio__box {
  border-color: #dc3545; }

.custom-radio__text {
  display: block;
  overflow: hidden; }

.custom-radio--white .custom-radio__box {
  background: #fff;
  border-color: #fff;
  box-shadow: 0 -0.0625rem 0 0 #DCDCDC; }

/* radio text style */
.custom-radio--text {
  font-family: "EncodeSans-Light", Arial, sans-serif;
  font-size: 0.9375rem;
  text-transform: uppercase; }

.custom-radio--text label {
  cursor: pointer; }

.custom-radio--text .custom-radio__input:checked ~ .custom-radio__text,
.custom-radio--text:hover {
  color: #5ac4be; }

.custom-radio--text .custom-radio__text {
  position: relative;
  padding-bottom: 0.375rem; }

.custom-radio--text .custom-radio__text:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #5ac4be;
  height: 0.125rem;
  transform: scaleX(0);
  transform-origin: center;
  transition: transform 200ms ease-in-out; }

.custom-radio--text .custom-radio__input:checked ~ .custom-radio__text:after {
  transform: scaleX(1); }

.radio-list .list-inline-item:not(:last-child) {
  margin-right: 1.875rem; }
  @media screen and (min-width: 768px) {
    .radio-list .list-inline-item:not(:last-child) {
      margin-right: 4.375rem; } }

.input-box__input {
  position: absolute;
  width: 0.0625rem;
  height: 0.0625rem;
  margin: -0.0625rem;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.input-box label {
  display: block;
  cursor: pointer; }

.input-box__box {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 1.5rem 1.5625rem 0.625rem 0.75rem;
  line-height: calc(22/17);
  max-width: 100%;
  position: relative;
  transition: background 200ms ease-in-out;
  height: 100%;
  font-size: 0.8125rem;
  background: #f4f4f4; }
  @media screen and (min-width: 768px) {
    .input-box__box {
      font-size: 1.0625rem;
      padding: 1.5rem 2.8125rem 0.625rem 0.875rem; } }

.input-box__box__icon {
  position: absolute;
  right: 0.625rem;
  top: 0.4375rem;
  font-size: 0.9375rem;
  transition: color 200ms ease-in-out;
  color: #5ac4be; }
  @media screen and (min-width: 768px) {
    .input-box__box__icon {
      font-size: 1.125rem; } }

.input-box__input:checked ~ .input-box__box,
.input-box:hover .input-box__box {
  background: #C6C6C6; }

.input-box__input:checked ~ .input-box__box .input-box__box__icon:before {
  content: ""; }

/* small input box */
@media screen and (min-width: 768px) {
  .input-box--sm .input-box__box {
    padding: 1rem 2.1875rem 0.5rem 0.875rem; } }

select.form-control:not([size]):not([multiple]) {
  height: calc(50rem / 16); }
  @media screen and (min-width: 768px) {
    select.form-control:not([size]):not([multiple]) {
      height: calc(50rem / 16); } }

.select {
  position: relative; }

.select > select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none; }

.select select::-ms-expand {
  display: none; }

.select:after {
  font-family: "iconfont";
  content: "";
  speak: none;
  position: absolute;
  top: 50%;
  right: 0.625rem;
  transform: translateY(-50%);
  pointer-events: none;
  font-size: 0.75rem;
  color: #5ac4be; }
  @media screen and (min-width: 768px) {
    .select:after {
      right: 1.25rem; } }

.dropdown__select {
  background: #fff;
  border: 0.0625rem solid #fff;
  box-shadow: 0 -0.0625rem 0 0 #DCDCDC;
  color: #5ac4be;
  width: 100%;
  font-size: 1rem;
  line-height: 1.125;
  padding: 0.9375rem;
  cursor: pointer;
  position: relative; }

.dropdown__select:after {
  font-family: "iconfont";
  content: "";
  speak: none;
  position: absolute;
  top: 50%;
  right: 0.625rem;
  transform: translateY(-50%);
  pointer-events: none;
  font-size: 0.75rem;
  color: #5ac4be; }
  @media screen and (min-width: 768px) {
    .dropdown__select:after {
      right: 0.9375rem; } }

.dropdown__select + .dropdown-menu {
  width: 100%; }

.dropdown-menu {
  box-shadow: 0 0.125rem 0.3125rem -0.0625rem rgba(0, 0, 0, 0.2); }

.dropdown-menu__list {
  padding: 0 0.9375rem; }

.dropdown-menu__list li + li {
  margin-top: 0.375rem; }

.form-errors {
  color: #CC0000; }

.form-errors:empty {
  display: none; }

.form-errors ul {
  list-style-type: none;
  padding: 0;
  margin: 0; }

.form-control-label,
.control-label {
  font-size: 0.75rem;
  line-height: 1.16667;
  padding-left: 0.5rem;
  margin-bottom: 0.5rem; }
  @media screen and (min-width: 768px) {
    .form-control-label,
    .control-label {
      padding-left: 1.125rem; } }

.content-block + .content-block,
.pimcore_area_content + .pimcore_area_content > .content-block {
  margin-top: calc(30rem/16); }

@media screen and (min-width: 768px) {
  .content-block + .content-block,
  .pimcore_area_content + .pimcore_area_content > .content-block {
    margin-top: calc(40rem/16); } }

.slide {
  padding-top: 1.875rem;
  padding-bottom: 1.875rem; }

@media screen and (min-width: 768px) {
  .slide {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem; } }

.slide-sm {
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem; }

.slide__head {
  padding-bottom: 1.875rem; }
  @media screen and (min-width: 768px) {
    .slide__head {
      padding-bottom: 3.75rem; } }

.slide__head h2 {
  color: #C09B57; }

.slide--no-top-padding.slide--no-top-padding {
  padding-top: 0; }

.slide.slide--with-rgb-flexigon {
  position: relative; }

@media screen and (min-width: 768px) {
  .slide.slide--with-rgb-flexigon:before {
    content: '';
    pointer-events: none;
    display: block;
    position: absolute;
    left: -5rem;
    bottom: -13rem;
    width: 900px;
    height: 900px;
    z-index: -1;
    background: url(/static/img/flexigons/flexigon-rgb-square.svg) -90px 100% no-repeat; } }

.page-heading {
  padding: 1.25rem 0; }

.wysiwyg > p,
.wysiwyg > ol,
.wysiwyg > ul,
.wysiwyg > table {
  margin-bottom: 0.6em; }

.wysiwyg > p:last-child,
.wysiwyg > ol:last-child,
.wysiwyg > ul:last-child,
.wysiwyg > table:last-child {
  margin-bottom: 0; }

.wysiwyg img {
  max-width: 100%;
  height: auto !important;
  /*override inline style from wysiwyg*/ }

.wysiwyg a:not(.btn) {
  color: #0077c1;
  font-family: "EncodeSans-Semibold", "Arial Black", sans-serif;
  font-weight: 400; }

.wysiwyg a:not(.btn):hover,
.wysiwyg a:not(.btn):focus,
.wysiwyg a:not(.btn):active {
  color: #0077c1;
  text-decoration: underline; }

.wysiwyg ol,
.wysiwyg ul {
  padding: 0; }

.wysiwyg ol li,
.wysiwyg ul li {
  position: relative;
  list-style: none;
  padding-left: 1.75rem; }
  @media screen and (min-width: 768px) {
    .wysiwyg ol li,
    .wysiwyg ul li {
      padding-left: 2.1875rem; } }

.wysiwyg ul li:before {
  content: '';
  display: block;
  position: absolute;
  left: .625rem;
  top: .375rem;
  width: .5rem;
  height: .625rem;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+Cjxzdmcgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDE0IDI1IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zOnNlcmlmPSJodHRwOi8vd3d3LnNlcmlmLmNvbS8iIHN0eWxlPSJmaWxsLXJ1bGU6ZXZlbm9kZDtjbGlwLXJ1bGU6ZXZlbm9kZDtzdHJva2UtbGluZWpvaW46cm91bmQ7c3Ryb2tlLW1pdGVybGltaXQ6MS40MTQyMTsiPgogICAgPGcgdHJhbnNmb3JtPSJtYXRyaXgoMSwwLDAsMSwtNjkyLjEyNSwtMTQ0MS40KSI+CiAgICAgICAgPGcgdHJhbnNmb3JtPSJtYXRyaXgoNC4xNjY2NywwLDAsNC4xNjY2NywwLDApIj4KICAgICAgICAgICAgPHBhdGggZD0iTTE2OC4wMTIsMzQ2LjI1OEMxNjcuODk4LDM0Ni4wNTkgMTY3LjY4NywzNDUuOTM3IDE2Ny40NTgsMzQ1LjkzN0wxNjYuMTEzLDM0NS45MzdMMTY2LjExLDM0NS45NDFMMTY3LjYwMiwzNDguNTM4QzE2Ny43MTYsMzQ4LjczNyAxNjcuNzE2LDM0OC45ODIgMTY3LjYwMiwzNDkuMTgxTDE2Ni4xMSwzNTEuNzc4TDE2Ni4xMTMsMzUxLjc4M0wxNjcuNDU4LDM1MS43ODNDMTY3LjY4NywzNTEuNzgzIDE2Ny44OTgsMzUxLjY2IDE2OC4wMTIsMzUxLjQ2MkwxNjkuMzIyLDM0OS4xODFDMTY5LjQzNiwzNDguOTgyIDE2OS40MzYsMzQ4LjczNyAxNjkuMzIyLDM0OC41MzhMMTY4LjAxMiwzNDYuMjU4WiIgc3R5bGU9ImZpbGw6cmdiKDY0LDYzLDY1KTtmaWxsLXJ1bGU6bm9uemVybzsiLz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPgo=");
  background-repeat: no-repeat;
  background-size: contain; }
  @media screen and (min-width: 768px) {
    .wysiwyg ul li:before {
      top: .5rem;
      left: 1rem; } }

.wysiwyg ol li + li,
.wysiwyg ol li ol,
.wysiwyg ul li + li,
.wysiwyg ul li ul {
  margin-top: 0.5rem; }
  @media screen and (min-width: 768px) {
    .wysiwyg ol li + li,
    .wysiwyg ol li ol,
    .wysiwyg ul li + li,
    .wysiwyg ul li ul {
      margin-top: 0.9375rem; } }

.wysiwyg ol {
  counter-reset: item; }

.wysiwyg ol li {
  display: block; }

.wysiwyg ol li:before {
  position: absolute;
  left: .5rem;
  top: 0;
  content: counters(item, ".") " ";
  counter-increment: item; }
  @media screen and (min-width: 768px) {
    .wysiwyg ol li:before {
      left: 1rem; } }

.wysiwyg ol li li:before {
  left: 0rem; }
  @media screen and (min-width: 768px) {
    .wysiwyg ol li li:before {
      left: .5rem; } }

.wysiwyg ol li li li:before {
  left: -.5rem; }
  @media screen and (min-width: 768px) {
    .wysiwyg ol li li li:before {
      left: 0rem; } }

/* wysiwyg text color white */
.text-white .wysiwyg ul li:before {
  background: #fff; }

.text-white .wysiwyg a:not(.btn) {
  text-decoration: underline;
  color: #fff; }

.text-white .wysiwyg a:not(.btn):hover,
.text-white .wysiwyg a:not(.btn):focus,
.text-white .wysiwyg a:not(.btn):active {
  color: #fff;
  text-decoration: none; }

.shariff .orientation-vertical li, .shariff .orientation-vertical li a {
  display: flex; }

.shariff .orientation-horizontal li, .shariff .orientation-horizontal li a {
  display: inline-flex; }

.shariff ul {
  padding-left: 0;
  list-style: none;
  margin-top: 0; }

.shariff li {
  border: 1px solid #8c8c8c; }

.shariff li, .shariff li a {
  color: #8c8c8c;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: calc(17rem/16);
  width: calc(45rem/16);
  height: calc(45rem/16);
  transition: all 0.2s ease; }

.shariff li:hover, .shariff li:hover a {
  color: #fff;
  background-color: #8c8c8c; }

.shariff li + li {
  margin-left: calc(4rem/16); }

.shariff-button .fab, .shariff-button .fas {
  display: inline-block;
  font-family: "iconfont";
  speak: none;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0); }

.shariff-button.facebook .fab:before {
  content: var(--icon-facebook); }

.shariff-button.twitter .fab:before {
  content: var(--icon-twitter); }

.shariff-button.googleplus .fab:before {
  content: var(--icon-google-plus); }

.shariff-button.pinterest .fab:before {
  content: var(--icon-pinterest); }

.shariff-button.mail .fas:before {
  content: var(--icon-mail); }

.shariff-button.whatsapp .fab:before {
  content: var(--icon-whatsapp); }

.shariff-button.addthis .fas:before {
  content: var(--icon-addthis); }

.shariff-button.linkedin .fab:before {
  content: var(--icon-linkedin); }

.hero {
  position: relative; }

.hero .slick-slider {
  overflow: hidden; }

.hero__slide {
  position: relative;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 58%;
  overflow: hidden; }
  @media screen and (min-width: 768px) {
    .hero__slide {
      padding-top: 0;
      min-height: 42.0625rem;
      min-height: 40.05952vw; } }

@media screen and (min-width: 768px) {
  .hero--sm-with-gradient:before,
  .hero--sm-with-gradient-full:before,
  .hero__slide:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: radial-gradient(circle, transparent 0%, transparent 18.65%, #000000 100%);
    z-index: 1;
    opacity: .6; }
  .hero--sm-with-gradient-full:before {
    background: #000;
    opacity: .45; } }

.hero__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: auto; }
  @media screen and (min-width: 768px) {
    .hero__bg {
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      height: 100%;
      width: auto; } }

.hero__content {
  position: relative;
  padding: 1rem 0 2.5rem;
  background: #3B3B39;
  z-index: 5; }
  @media screen and (min-width: 768px) {
    .hero__content {
      padding: 1rem 3.75rem;
      background: transparent; } }

@media screen and (max-width: 767px) {
  .hero__content--singleitem {
    padding: 1.5rem 0; } }

.hero__title {
  font-size: 2rem;
  line-height: 1; }
  @media screen and (min-width: 768px) {
    .hero__title {
      font-size: 3.125rem; } }
  @media screen and (min-width: 1200px) {
    .hero__title {
      font-size: 4.5rem; } }

.hero__text {
  margin-top: 0.9375rem; }
  @media screen and (min-width: 768px) {
    .hero__text {
      font-size: 1.125rem;
      margin-top: 2rem; } }

.hero__badge {
  font-size: 0.9375rem;
  line-height: 1.2;
  padding: 0.5rem 1.125rem; }

.hero__scroll {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%) translateY(50%) rotate(90deg);
  color: #fff;
  font-size: 3.125rem; }

.hero__scroll:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  font-size: 2.75rem;
  line-height: 1px;
  color: #5ac4be;
  transition: color 200ms ease-in-out; }

.hero__scroll__icon {
  position: absolute;
  top: 1.55rem;
  left: 0.1875rem;
  font-size: 1.875rem;
  line-height: 1px;
  color: #fff;
  z-index: 1; }

.hero__scroll:hover {
  color: #fff; }

.hero__scroll:hover:after {
  color: #0077c1; }

@media screen and (min-width: 768px) {
  .hero__content--top {
    position: absolute;
    left: 0;
    right: 0;
    top: 20%; } }

@media screen and (min-width: 1200px) {
  .hero__content--top {
    top: 30%; } }

@media screen and (min-width: 768px) {
  .hero__content--middle {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%); } }

.hero__arrow {
  position: absolute;
  bottom: 12%;
  left: 50%;
  transform: translateX(-100%);
  font-size: 0.8125rem;
  color: #fff;
  padding: 0 2.5rem;
  z-index: 5; }

.hero__arrow--next {
  transform: translateX(0); }

.hero__arrow__icon {
  font-size: 1.875rem;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%); }

.hero__arrow--next .hero__arrow__icon {
  left: auto;
  right: 0; }

/* small hero */
.hero--sm .hero__title {
  font-size: 1.75rem;
  margin-bottom: 0.125rem; }
  @media screen and (min-width: 768px) {
    .hero--sm .hero__title {
      font-size: 2.875rem;
      line-height: 1.21739; } }

.hero--sm .hero__text {
  font-size: 1.25rem;
  margin-top: 0; }

@media screen and (max-width: 767px) {
  .hero .slick-arrow {
    top: 20%; }
  .hero .slick-dots {
    left: 0;
    right: 0;
    transform: none;
    display: flex;
    padding: 0;
    margin-top: 58%; }
  .hero .slick-dots li {
    align-items: flex-end;
    display: flex;
    flex: 1 1 100%; }
  .hero .slick-dots button {
    width: 100%;
    bottom: 0.3125rem; } }

.contact-block__text {
  font-size: 1.125rem; }
  @media screen and (min-width: 768px) {
    .contact-block__text {
      margin-left: -18.125rem; } }

@media screen and (min-width: 768px) {
  .contact-block__bottom {
    margin-top: -3.625rem; } }

.contact-block__text__title {
  padding: 1.25rem 0;
  font-size: 1.5rem;
  line-height: 1.23333; }
  @media screen and (min-width: 768px) {
    .contact-block__text__title {
      font-size: 1.625rem; } }
  @media screen and (min-width: 1400px) {
    .contact-block__text__title {
      font-size: 1.875rem; } }

.contact-block__decorator {
  position: relative; }

.contact-block__decorator__flag {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -0.375rem;
  z-index: 1; }

.contact-info {
  font-size: 0.875rem; }

.contact-info__title {
  font-size: 1.375rem;
  border-bottom: 0.0625rem solid #C6C6C6;
  padding-bottom: 0.375rem;
  margin-bottom: 1.125rem; }

.contact-info__data {
  border-top: 0.0625rem solid #C6C6C6;
  border-bottom: 1px solid #C6C6C6;
  margin: 0.9375rem 0;
  padding: 0.5rem 0; }

.contact-info__data__link-icon {
  vertical-align: -.1em;
  margin-right: 0.5rem; }

.decorator-item {
  position: relative;
  width: 300px;
  height: 173.21px;
  margin: 86.60px 0;
  border-left: solid 1px #DCDCDC;
  border-right: solid 1px #DCDCDC; }

.decorator-item:before,
.decorator-item:after {
  content: "";
  position: absolute;
  z-index: 1;
  width: 212.13px;
  height: 212.13px;
  -webkit-transform: scaleY(0.5774) rotate(-45deg);
  -ms-transform: scaleY(0.5774) rotate(-45deg);
  transform: scaleY(0.5774) rotate(-45deg);
  background-color: inherit;
  left: 42.9340px; }

.decorator-item:before {
  top: -106.0660px;
  border-top: solid 1.4142px #DCDCDC;
  border-right: solid 1.4142px #DCDCDC; }

.decorator-item:after {
  bottom: -106.0660px;
  border-bottom: solid 1.4142px #DCDCDC;
  border-left: solid 1.4142px #DCDCDC; }

/* base form styles in boostrap/_custom.scss */
.form-control {
  font-family: "EncodeSans-Light", Arial, sans-serif;
  font-weight: 400;
  padding: 0.75rem 0.625rem;
  font-size: 1rem;
  line-height: 1.22222; }
  @media screen and (min-width: 768px) {
    .form-control {
      padding: 0.875rem 1rem; } }

.form-control:focus {
  box-shadow: none;
  color: #5ac4be; }

.form-group {
  position: relative;
  margin-bottom: 1.25rem; }

.form-group--sm {
  margin-bottom: 0.75rem; }

.form-control-btn {
  position: absolute;
  right: 0;
  top: 0.5rem;
  font-size: 1.125rem;
  background: #fff;
  padding: 0 0.125rem 0 0.3125rem; }
  @media screen and (min-width: 768px) {
    .form-control-btn {
      font-size: 1.25rem; } }

/* form colors */
.form-control--light {
  background: transparent;
  border: 0;
  border-bottom: 0.0625rem solid #999999;
  padding: 0.625rem 0.125rem; }

.form-control--white {
  background: #fff;
  box-shadow: 0 -0.0625rem 0 0 #DCDCDC;
  color: #5ac4be; }

.required-symbol {
  font-size: 0.875rem;
  line-height: 1;
  vertical-align: 0.2em;
  color: #0077c1; }

.input-wrapper {
  position: relative; }

.input-wrapper .required-symbol {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  background: #DCDCDC;
  width: 1rem;
  text-align: center;
  padding: 0.3125rem 0; }

.has-error .form-control,
.has-error .custom-checkbox__box,
.has-error .custom-radio__box {
  border-color: #CC0000; }

.has-error .input-wrapper .required-symbol {
  color: #fff;
  background: #CC0000; }

.has-success .form-control,
.has-success .custom-checkbox__box,
.has-success .custom-radio__box {
  border-color: #24b377; }

.has-success .input-wrapper .required-symbol {
  color: #fff;
  background: #24b377; }

.filter-title {
  border-bottom: 0.0625rem solid #DCDCDC;
  padding-bottom: 0.125rem; }

.nav-tabs {
  margin-bottom: 1.25rem; }
  @media screen and (min-width: 768px) {
    .nav-tabs {
      margin-bottom: 2.1875rem; } }

.nav-tabs .nav-item {
  padding: 0 0.625rem; }
  @media screen and (min-width: 768px) {
    .nav-tabs .nav-item {
      padding: 0 1.25rem; } }

.nav-tabs .nav-link {
  font-size: 0.9375rem;
  line-height: 1.2;
  position: relative;
  padding: 0.5rem 0.3125rem; }
  @media screen and (min-width: 768px) {
    .nav-tabs .nav-link {
      padding: 0.5rem 1rem; } }

.nav-tabs .nav-link:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0.3125rem;
  right: 0.3125rem;
  height: 0.125rem;
  background: #5ac4be;
  transform: scaleX(0);
  transition: transform 200ms ease-in-out;
  transform-origin: center; }
  @media screen and (min-width: 768px) {
    .nav-tabs .nav-link:after {
      left: 1rem;
      right: 1rem; } }

.active.nav-link:after {
  transform: scaleX(1); }

/* icon tabs */
.nav-tabs--icons {
  margin: 0 -0.625rem 0.9375rem; }

.nav-tabs.nav-tabs--icons .nav-item {
  padding: 0; }

.nav-tabs--icons .nav-link {
  padding: 0 0.625rem;
  font-size: 1.5rem;
  color: #C6C6C6; }

.nav-tabs--icons .nav-link:after {
  content: none; }

.nav-tabs--icons .nav-link.active {
  color: #0077c1; }

/* nav pills */
.nav-pills .nav-link {
  background: #fff;
  font-size: 1.125rem;
  line-height: calc(26/20);
  padding: 0.75rem 1.875rem 0.75rem 0.75rem;
  position: relative; }
  @media screen and (min-width: 768px) {
    .nav-pills .nav-link {
      font-size: 1.25rem;
      padding: 1.25rem 2.5rem 0.9375rem 1.25rem; } }

.nav-pills .nav-link:hover {
  background: #DCDCDC; }

.nav-pills .nav-link__icon {
  position: absolute;
  right: 0.75rem;
  font-size: 1.0625rem;
  top: 50%;
  transform: translateY(-50%); }
  @media screen and (min-width: 768px) {
    .nav-pills .nav-link__icon {
      right: 1.25rem; } }

.nav-pills .nav-item + .nav-item {
  margin-top: 0.625rem; }

.solution-box {
  position: relative; }

.solution-box__bg {
  width: 100%;
  background-size: cover;
  background-position: center;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0; }

.solution-box__bg--half {
  width: 50%; }

.solution-box__content {
  padding: 0.9375rem; }
  @media screen and (min-width: 768px) {
    .solution-box__content {
      padding: 2.5rem 2.5rem 3.125rem;
      min-height: 29.375rem;
      height: 100%; } }
  @media screen and (min-width: 992px) {
    .solution-box__content {
      min-height: 32.5rem; } }
  @media screen and (min-width: 1200px) {
    .solution-box__content {
      min-height: 45rem; } }
  @media screen and (min-width: 1400px) {
    .solution-box__content {
      padding: 3.75rem 3.75rem 4.375rem; } }

.solution-box__content__title {
  font-size: 2rem; }
  @media screen and (min-width: 768px) {
    .solution-box__content__title {
      font-size: 2.875rem; } }
  @media screen and (min-width: 1200px) {
    .solution-box__content__title {
      font-size: 3.375rem; } }
  @media screen and (min-width: 1400px) {
    .solution-box__content__title {
      font-size: 4.5rem; } }

.solution-box__content__fact {
  position: relative;
  padding: 0.5rem;
  font-size: 1rem;
  line-height: 1.3;
  min-height: 4.0625rem;
  width: 100%; }
  @media screen and (min-width: 768px) {
    .solution-box__content__fact {
      padding: 0.5rem 0.8125rem 0.5rem;
      min-height: 6.25rem;
      font-size: 1.125rem; } }
  @media screen and (min-width: 1400px) {
    .solution-box__content__fact {
      font-size: 1.25rem; } }

.solution-box__content__fact__icon {
  position: absolute;
  right: 0.625rem;
  top: 0.625rem;
  font-size: 1.125rem; }

@media screen and (min-width: 1400px) {
  .solution-box__content__small-content {
    width: 68%; } }

.solution-box__content--hover {
  color: #282828; }
  @media screen and (min-width: 768px) {
    .solution-box__content--hover {
      color: #fff; } }

.solution-box__content.solution-box__content--no-height {
  min-height: auto; }

.pre-footer {
  position: relative;
  padding: 2.1875rem 0 4.375rem; }
  @media screen and (min-width: 768px) {
    .pre-footer {
      padding: 3.125rem 0 5rem; } }

.pre-footer__bg {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 100%;
  max-height: 12.5rem;
  z-index: -1; }
  @media screen and (min-width: 768px) {
    .pre-footer__bg {
      max-width: 90%;
      height: auto;
      max-height: none; } }
  @media screen and (min-width: 1200px) {
    .pre-footer__bg {
      max-height: 43.75rem; } }

/* with border */
.pre-footer--bordered {
  border-top: 10px solid #DCDCDC; }

.main-footer {
  border-top: 0.625rem solid #000;
  padding: 4.375rem 0 1.25rem;
  font-size: 0.875rem;
  line-height: 1.16667;
  color: #fff;
  background: url(/static/img/flexigons/flexigon-white-square.svg) #3B3B39 no-repeat;
  background-repeat: no-repeat;
  background-position: -130px -555px;
  background-size: 700px 700px; }
  @media screen and (min-width: 768px) {
    .main-footer {
      padding: 2.5rem 0 1.875rem;
      font-size: 1rem; } }
  @media screen and (min-width: 1200px) {
    .main-footer {
      padding: 4.6875rem 0 4.0625rem;
      font-size: 1.125rem;
      background-position: 10% -600px;
      background-size: 800px 800px; } }
  @media screen and (min-width: 1400px) {
    .main-footer {
      background-position: 33% -600px; } }

.main-footer__social-list {
  font-size: 1.5rem;
  margin-bottom: 0.9375rem; }
  @media screen and (min-width: 768px) {
    .main-footer__social-list {
      font-size: 1.875rem;
      margin-bottom: 1.5625rem; } }
  @media screen and (min-width: 1200px) {
    .main-footer__social-list {
      margin-bottom: 3.25rem; } }

.main-footer__social-list li:not(:last-child) {
  margin-right: 0.75rem; }
  @media screen and (min-width: 768px) {
    .main-footer__social-list li:not(:last-child) {
      margin-right: 2.375rem; } }

.main-footer__links {
  font-size: 0.875rem;
  line-height: 1.1875;
  margin-bottom: 0.625rem;
  margin-top: 1.25rem; }
  @media screen and (min-width: 768px) {
    .main-footer__links {
      margin-top: 0;
      margin-bottom: 0; } }
  @media screen and (min-width: 1200px) {
    .main-footer__links {
      font-size: 1rem; } }

.main-footer__links li {
  display: inline-block;
  padding: 0.125rem 0.625rem 0.125rem 0.375rem;
  border-right: 0.0625rem solid #565656; }
  @media screen and (min-width: 768px) {
    .main-footer__links li {
      padding: 0.625rem 0.9375rem 0.625rem 0.6875rem; } }
  @media screen and (min-width: 1200px) {
    .main-footer__links li {
      padding: 0.625rem 1.75rem 0.625rem 1.5rem; } }

.page-link {
  color: #282828;
  font-size: 0.9375rem;
  line-height: calc(22/15);
  padding: 0.5rem 0.625rem;
  border-bottom: 1px solid #DCDCDC; }

.page-item.active .page-link {
  font-family: "EncodeSans-Semibold", "Arial Black", sans-serif;
  font-weight: 400;
  color: #282828; }

.page-item {
  padding: 0 0.375rem; }

.page-item--arrow:last-child {
  margin-left: 1.375rem; }

.page-item--arrow:first-child {
  margin-right: 1.375rem; }

.page-item--arrow .page-link {
  border: none;
  color: #5ac4be; }

@media screen and (min-width: 768px) {
  .text-with-media {
    overflow: hidden; }
  .text-with-media__media-container {
    float: right;
    margin-left: 2.375rem;
    max-width: 47%; }
  .text-with-media__media {
    display: block;
    margin-bottom: 0.625rem;
    max-width: 100%;
    height: auto; }
  .text-with-img--media-left .text-with-media__media-container {
    float: left;
    margin-right: 2.375rem;
    margin-left: 0; }
  .text-with-media__media.text-with-media__media--video {
    width: 26.25rem;
    height: auto; } }

@media screen and (max-width: 767px) {
  .text-with-media {
    display: flex;
    flex-direction: column; }
  .text-with-media__media-container {
    order: 2;
    margin-top: 1.875rem; }
  .text-with-media__media {
    width: 100%; } }

.console {
  position: fixed;
  bottom: 0.9375rem;
  left: 0.9375rem;
  right: 0.9375rem;
  opacity: 0;
  transform: translateY(100%);
  transition: transform 200ms ease-in-out, opacity 200ms ease-in-out;
  z-index: 10; }
  @media screen and (min-width: 768px) {
    .console {
      left: auto;
      bottom: 0;
      right: 0; } }

.console.is-affix {
  opacity: 1;
  transform: translateY(0); }

.console.is-affix-bottom {
  position: absolute; }

.console li + li .console__btn {
  margin-left: 0.625rem; }
  @media screen and (min-width: 768px) {
    .console li + li .console__btn {
      margin-left: 0;
      border-left: 0.0625rem solid #fff; } }

.console__btn {
  padding: 0.75rem;
  font-size: 0.75rem;
  line-height: 1.2;
  background: #0077c1;
  color: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0 0 0.3125rem rgba(0, 0, 0, 0.2); }
  @media screen and (min-width: 768px) {
    .console__btn {
      box-shadow: none;
      font-size: 0.9375rem;
      width: 7.5rem;
      height: 7.5rem; } }

.console__btn__icon {
  font-size: 1.375rem;
  display: block; }
  @media screen and (min-width: 768px) {
    .console__btn__icon {
      font-size: 1.875rem;
      margin-bottom: 0.75rem; } }

.console__btn:hover,
.console__btn:focus {
  color: #fff;
  background: #005f9b; }

.language-item {
  border-bottom: 0.0625rem solid #DCDCDC;
  font-size: 0.9375rem;
  padding: 0.4375rem 0.9375rem 0.4375rem 0.375rem; }

.language-item__link:not(:last-child) {
  margin-right: 0.5rem; }

.language-item__link:hover {
  text-decoration: underline; }

.language-item__link.is-active {
  color: #999999;
  pointer-events: none; }

@media screen and (min-width: 768px) {
  .modal-dialog {
    max-width: 52.5rem; } }

.modal-header {
  border-bottom: 0;
  padding: 0.9375rem 1.875rem 0 1.25rem;
  position: relative; }
  @media screen and (min-width: 768px) {
    .modal-header {
      padding: 2.5rem 3.875rem 0; } }

.modal-header__close {
  position: absolute;
  right: 1rem;
  top: 1rem;
  font-size: 1rem;
  opacity: 1;
  transition: color 200ms ease-in-out; }

.modal-header__close:hover {
  color: #5ac4be;
  opacity: 1; }

.modal-title {
  font-size: 1.375rem; }

.modal-body {
  padding: 0.9375rem 1.25rem 1.25rem; }
  @media screen and (min-width: 768px) {
    .modal-body {
      padding: 2.25rem 3.875rem 3.875rem; } }

.hotspot-container {
  position: relative; }

.hotspot {
  position: absolute;
  width: 2rem;
  height: 2.125rem;
  transform: translateY(-50%) translateX(-50%); }
  @media screen and (min-width: 768px) {
    .hotspot {
      width: 2.5rem;
      height: 2.8125rem; } }

.hotspot__toggle:after,
.hotspot__toggle:before {
  font-family: 'iconfont';
  speak: none;
  line-height: 1;
  content: "";
  position: absolute;
  top: 0;
  left: -0.1875rem;
  transform: rotate(-90deg);
  color: rgba(0, 0, 0, 0.5);
  font-size: 1.875rem; }
  @media screen and (min-width: 768px) {
    .hotspot__toggle:after,
    .hotspot__toggle:before {
      font-size: 2.5rem; } }

.hotspot__toggle:after {
  color: #5ac4be;
  font-size: 1.375rem;
  transform: rotate(-90deg) translateX(-4px) translateY(4px);
  transition: transform 100ms ease-in-out;
  transform-origin: center; }
  @media screen and (min-width: 768px) {
    .hotspot__toggle:after {
      font-size: 1.875rem;
      transform: rotate(-90deg) translateX(-0.3125rem) translateY(0.375rem); } }

/*.hotspot__toggle:hover:after {
  transform: rotate(-90deg) translateX(-14%) translateY(17%) scale(1.32);

  @media screen and (min-width: 768px) {
    transform: rotate(-90deg) translateX(-14%) translateY(20%) scale(1.32);
  }
}*/
.hotspot__toggle__icon {
  position: absolute;
  top: 0.5rem;
  left: 0.4375rem;
  font-size: 0.8125rem;
  color: #fff;
  z-index: 1; }
  @media screen and (min-width: 768px) {
    .hotspot__toggle__icon {
      font-size: 0.9375rem;
      top: 0.6875rem;
      left: 0.8125rem; } }

.hotspot__text {
  display: none;
  position: absolute;
  top: -1.25rem;
  left: 50%;
  transform: translateX(-50%) translateY(-100%);
  background: #5ac4be;
  color: #fff;
  border: 0.0625rem solid #fff;
  white-space: nowrap;
  font-size: 0.9375rem;
  line-height: 1.2;
  padding: 1rem 1.125rem; }

.hotspot__text:after {
  content: '';
  position: absolute;
  left: 50%;
  top: 100%;
  height: 1.1875rem;
  width: 0.0625rem;
  background: #fff;
  transform: translateX(-50%); }

.hotspot.is-open .hotspot__text {
  display: block; }

/* hotspot details */
.hotspot-detail {
  background: rgba(244, 244, 244, 0.95);
  display: none;
  position: relative;
  margin-top: 0.625rem; }
  @media screen and (min-width: 768px) {
    .hotspot-detail {
      display: block;
      margin-top: 0;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
      visibility: hidden;
      transition: opacity 250ms ease-in-out;
      overflow-x: hidden;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch; } }

.is-open.hotspot-detail {
  display: block; }
  @media screen and (min-width: 768px) {
    .is-open.hotspot-detail {
      opacity: 1;
      visibility: visible; } }

.hotspot-detail__content {
  max-height: 100%;
  padding: 3.125rem 0.9375rem 0.9375rem; }
  @media screen and (min-width: 768px) {
    .hotspot-detail__content {
      padding: 1.875rem; } }
  @media screen and (min-width: 1200px) {
    .hotspot-detail__content {
      padding: 5.625rem; } }

.hotspot-detail__close {
  position: absolute;
  right: 0.9375rem;
  top: 0.9375rem; }
  @media screen and (min-width: 768px) {
    .hotspot-detail__close {
      right: 2rem;
      top: 2rem; } }

.filter-form {
  font-size: 0.9375rem; }

.filter-group.is-disabled {
  opacity: .6;
  pointer-events: none;
  cursor: default; }

.map-infobox {
  background: #fff;
  box-shadow: 0 0 0.25rem 0 rgba(0, 0, 0, 0.3);
  padding: 0.9375rem 1.25rem 1.875rem 1.25rem;
  font-family: "EncodeSans-Light", Arial, sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.28571;
  position: relative; }
  @media screen and (min-width: 768px) {
    .map-infobox {
      padding: 1.375rem 1.5625rem 1.875rem 1.5625rem; } }

.map-infobox:after {
  font-family: 'iconfont';
  content: "";
  position: absolute;
  bottom: -18px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 30px;
  color: #0077c1; }

.infobox--poi-turquoise:after {
  color: #5ac4be; }

.infobox--poi-orange:after {
  color: #FF7100; }

.infobox--poi-brown:after {
  color: #A66E00; }

.infobox--poi-purple:after {
  color: #5a3eb9; }

.map-infobox__close {
  position: absolute;
  top: 0.8125rem;
  right: 0.5rem; }

.map-infobox__title {
  font-size: 1rem;
  margin-bottom: 0; }

.map-infobox__address {
  line-height: 1.57143; }

.map-infobox__detail {
  margin-top: 1.25rem; }

.map-infobox__contact__icon {
  vertical-align: -0.2em;
  margin-right: 9px; }

.technogramm__date,
.technogramm__btn {
  display: block;
  padding: .625rem .5rem .5rem .5rem;
  font-size: 13px;
  color: #fff;
  text-align: center;
  border-right: 1px solid #fff;
  background: #5ac4be;
  -webkit-appearance: none; }

.technogramm__date {
  border-right: 0;
  background: #272727; }

.technogramm__btn > .icon {
  position: relative;
  margin-right: .5rem;
  line-height: 1px; }

.technogramm__btn > .icon-ansicht {
  font-size: 1rem;
  top: .125rem; }

/* overlay */
.issuu-overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
  display: none;
  background: #272727; }

.issuu-overlay.is-visible {
  display: block; }

.issuu-overlay__close {
  color: #fff;
  font-size: 1.5rem;
  position: absolute;
  right: 0rem;
  top: 1rem;
  padding: 1.5rem;
  opacity: .85; }

.issuu-overlay__close:hover,
.issuu-overlay__close:focus {
  color: #fff;
  opacity: 1; }

.location-item {
  font-size: 0.875rem;
  line-height: 1.28571;
  padding: 0.9375rem; }
  @media screen and (min-width: 768px) {
    .location-item {
      padding: 1.875rem; } }

.location-item + .location-item {
  margin-top: 0.3125rem; }

.location-item__title {
  font-size: 0.875rem;
  line-height: 1;
  margin-bottom: 0.3125rem; }

.location-item__address {
  font-size: 0.875rem;
  line-height: 1.57143; }

.location-item__contact + .location-item__contact {
  margin-top: 0.5625rem; }

.location-item__contact__icon {
  margin-right: 0.5rem;
  vertical-align: -0.2em; }

/* location collapse groups */
.location-item-group__body {
  margin-bottom: 1.5625rem; }

.location-item-group__title {
  font-size: 1.125rem; }

.location-item-group__title > a {
  position: relative;
  padding: 1.25rem 1.875rem 1.25rem 0;
  border-bottom: 2px solid #DCDCDC; }

.location-item-group__title__icon {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0.75rem; }

.figure {
  position: relative; }

.figure__caption {
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-size: 0.875rem;
  line-height: 1.28571;
  padding: 0.625rem 2.5rem 0.625rem 0.625rem; }
  @media screen and (min-width: 768px) {
    .figure__caption {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 1.25rem 3.75rem 1.25rem 1.5rem; } }

.figure__caption__expand {
  position: absolute;
  top: 50%;
  right: 0.625rem;
  font-size: 1.625rem;
  transform: translateY(-50%); }
  @media screen and (min-width: 768px) {
    .figure__caption__expand {
      right: 1.25rem;
      font-size: 1.875rem; } }

.figure__btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%) rotate(90deg);
  z-index: 1;
  color: #fff;
  font-size: 4.375rem;
  cursor: pointer; }
  @media screen and (min-width: 768px) {
    .figure__btn {
      font-size: 7.375rem; } }

.figure__btn:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  color: #5ac4be;
  font-size: 3.75rem;
  transition: color 200ms ease-in-out; }
  @media screen and (min-width: 768px) {
    .figure__btn:after {
      font-size: 6.375rem; } }

.figure__btn__icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%) rotate(-90deg);
  font-size: 1.5rem;
  z-index: 1; }
  @media screen and (min-width: 768px) {
    .figure__btn__icon {
      font-size: 2.125rem; } }

.figure__btn__icon.icon-play {
  margin-top: -0.25rem; }

.figure:hover .figure__btn:after,
.figure__btn:hover:after {
  color: #0077c1; }

.lg-backdrop {
  background: #f4f4f4; }

.lg-actions .lg-next,
.lg-actions .lg-prev {
  background: transparent;
  border: 0;
  border-radius: 0;
  color: #5ac4be;
  width: 1.875rem;
  height: 3.125rem;
  font-size: 1.125rem; }
  @media screen and (min-width: 1200px) {
    .lg-actions .lg-next,
    .lg-actions .lg-prev {
      width: 3.125rem;
      height: 4.375rem;
      font-size: 1.875rem; } }

.lg-actions .lg-next:before,
.lg-actions .lg-prev:after {
  content: none; }

.lg-actions .lg-prev:hover {
  color: #5ac4be;
  transform: translateX(-0.3125rem); }

.lg-actions .lg-next:hover {
  color: #5ac4be;
  transform: translateX(0.3125rem); }

.lg-sub-html, .lg-toolbar {
  background: transparent; }

.lg-toolbar {
  background: #f4f4f4; }

.lg-sub-html {
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 0.625rem;
  font-size: 0.875rem; }
  @media screen and (min-width: 768px) {
    .lg-sub-html {
      font-size: 1rem;
      padding: 0.625rem 3.75rem; } }

#lg-counter {
  color: #282828; }

.lg-toolbar .lg-icon {
  color: #282828; }

.lg-toolbar .lg-icon:hover {
  color: #5ac4be; }

.lg-toolbar .lg-close {
  font-size: 1rem;
  padding: 0.625rem 0;
  width: 3.125rem; }
  @media screen and (min-width: 768px) {
    .lg-toolbar .lg-close {
      padding: 1.375rem 0;
      width: 4.375rem; } }

.lg-toolbar .lg-close:after {
  content: "";
  font-family: iconfont; }

.lightbox-download {
  font-size: 0.8125rem; }

.lightbox-download:hover {
  text-decoration: underline; }

.lightbox-download__icon {
  font-size: 1.25rem;
  margin-right: 0.5rem;
  vertical-align: -0.3em; }

/*
.lg-outer .lg-video-cont {
    max-width: 1500px !important; !* overwrite inline style *!
}*/
.testimonial__text {
  position: relative;
  font-size: 1rem;
  padding: 0.75rem 0; }
  @media screen and (min-width: 768px) {
    .testimonial__text {
      font-size: 1.375rem; } }

.testimonial__text:before,
.testimonial__text:after {
  content: "";
  font-family: iconfont;
  position: absolute;
  top: -0.9375rem;
  left: 0;
  color: #5ac4be;
  font-size: 1.125rem; }
  @media screen and (min-width: 768px) {
    .testimonial__text:before,
    .testimonial__text:after {
      top: -1.875rem;
      font-size: 1.75rem; } }

.testimonial__text:after {
  top: auto;
  left: auto;
  bottom: -0.3125rem;
  right: 0;
  transform: rotate(180deg); }
  @media screen and (min-width: 768px) {
    .testimonial__text:after {
      bottom: -1.25rem; } }

.testimonial__author {
  position: relative;
  padding-top: 0.625rem;
  font-size: 0.875rem; }
  @media screen and (min-width: 768px) {
    .testimonial__author {
      padding-top: 1.125rem;
      font-size: 0.9375rem; } }

.testimonial__author:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 6.25rem;
  height: 0.0625rem;
  background: #3B3B39;
  opacity: .3; }

.hexagon-img {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 118%;
  margin-bottom: 1.25rem; }
  @media screen and (min-height: 768px) {
    .hexagon-img {
      margin-bottom: 0; } }

.hexagon-img__svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.hexagon-img__svg__border {
  position: absolute;
  top: 12px;
  left: 3px;
  bottom: 3px;
  right: 3px;
  background-size: contain;
  background-repeat: no-repeat; }
  @media screen and (max-width: 992px) {
    .hexagon-img__svg__border {
      top: 9px; } }

/*todo*/
/*.overlay-box:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,.2);
}*/
.overlay-box {
  position: fixed;
  right: 50%;
  margin-right: -45rem;
  top: 0;
  border-top: 0.3125rem solid #5ac4be;
  z-index: 1;
  width: 25rem;
  padding: 2.1875rem 1.875rem;
  font-size: 0.9375rem;
  transform: translateY(-100%);
  opacity: 0;
  transition: transform 250ms ease-in-out, opacity 250ms ease-in-out; }

.overlay-box__close {
  position: absolute;
  right: 0;
  top: 0;
  padding: 0.9375rem; }

.overlay-box.is-visible {
  transform: translateY(0);
  opacity: 1; }

.navbar.is-transitioning .overlay-box {
  opacity: 0; }

.password-strength {
  font-size: 0.875rem; }

.password-strength__item + .password-strength__item {
  margin-top: 0.3125rem; }

.password-strength__item__icon {
  color: #dc3545;
  font-size: 0.625rem;
  margin-right: 0.375rem;
  vertical-align: 0.1em; }

.password-strength__item__icon:before {
  content: ""; }

.is-valid .password-strength__item__icon {
  color: #5ac4be; }

.is-valid .password-strength__item__icon:before {
  content: ""; }

.loading-spinner-container {
  position: relative; }

.loading-spinner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.6);
  z-index: 2; }

.loading-spinner__dots {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%); }

.loading-spinner__dots__icon {
  border-radius: 100%;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  color: #0077c1;
  font-size: 1.25rem;
  display: inline-block; }

.loading-spinner__dots__icon:nth-child(1) {
  animation-delay: -0.32s; }

.loading-spinner__dots__icon:nth-child(2) {
  animation-delay: -0.16s; }

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    transform: scale(0); }
  40% {
    transform: scale(1); } }

/* base form styles in boostrap/_custom.scss */
.formbuilder-form .form-control {
  font-family: "EncodeSans-Light", Arial, sans-serif;
  font-weight: 400;
  padding: 0.75rem 0.625rem;
  font-size: 1rem;
  line-height: 1.22222;
  background: #fff;
  box-shadow: 0 -0.0625rem 0 0 #DCDCDC;
  color: #5ac4be; }
  @media screen and (min-width: 768px) {
    .formbuilder-form .form-control {
      padding: 0.875rem 1rem; } }

.formbuilder-form .form-control:focus {
  box-shadow: none;
  color: #5ac4be; }

.formbuilder-form .has-error .form-control {
  border-color: #CC0000; }

.formbuilder-form .EditingFormErrorLabel {
  color: #CC0000;
  padding-left: 0.5rem; }

.formbuilder-form .has-success .form-control {
  border-color: #24b377; }

.formbuilder-form .ExplanationText {
  color: #696969; }

.td.EditingFormValueCell {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center; }

.td.EditingFormValueCell > .editing-form-control-nested-control,
.td.EditingFormValueCell > .ExplanationText {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  position: relative;
  width: 100%;
  min-height: 1px; }
  @media screen and (min-width: 768px) {
    .td.EditingFormValueCell > .editing-form-control-nested-control,
    .td.EditingFormValueCell > .ExplanationText {
      flex: 0 0 50%;
      max-width: 50%; } }

.formbuilder-form .tr + .tr {
  margin-top: 1.25rem; }

.formbuilder-form .EditingFormButtonCell {
  text-align: right; }

/* checkboxes */
.formbuilder-form .checkbox,
.formbuilder-form .ktc-checkbox {
  cursor: pointer;
  font-size: 0.875rem;
  line-height: 1.46667;
  font-family: "EncodeSans-Light", Arial, sans-serif;
  font-weight: 400; }

.formbuilder-form .checkbox + .checkbox,
.formbuilder-form .ktc-checkbox + .ktc-checkbox {
  margin-top: 0.75rem; }

.formbuilder-form .checkbox label,
.formbuilder-form .ktc-checkbox label {
  cursor: pointer;
  position: relative;
  padding-left: 1.875rem; }

.formbuilder-form .checkbox:before,
.formbuilder-form .ktc-checkbox:before,
.formbuilder-form .checkbox:after,
.formbuilder-form .ktc-checkbox:after {
  content: " ";
  display: table; }

.formbuilder-form .checkbox:after,
.formbuilder-form .ktc-checkbox:after {
  clear: both; }

.formbuilder-form .checkbox input,
.formbuilder-form .ktc-checkbox input {
  position: absolute;
  width: 0.0625rem;
  height: 0.0625rem;
  margin: -0.0625rem;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.formbuilder-form .checkbox label:after,
.formbuilder-form .ktc-checkbox label:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  border: 1px solid #fff;
  background: #DCDCDC;
  width: 1.375rem;
  height: 1.375rem;
  background: #fff;
  box-shadow: 0 -1px 0 0 #DCDCDC; }

.formbuilder-form .checkbox label:before,
.formbuilder-form .ktc-checkbox label:before {
  content: "";
  font-family: iconfont;
  position: absolute;
  top: 50%;
  left: 0.5rem;
  transform: translateY(-50%);
  font-size: 1.125rem;
  color: #5ac4be;
  visibility: hidden;
  z-index: 1; }

.formbuilder-form .checkbox input:checked ~ label:before,
.formbuilder-form .ktc-checkbox input:checked ~ label:before {
  visibility: visible; }

.formbuilder-form .checkbox input:not(:checked):focus ~ label:after,
.formbuilder-form .ktc-checkbox input:not(:checked):focus ~ label:after {
  border-color: #5ac4be; }

.formbuilder-form .has-error .checkbox label:after,
.formbuilder-form .has-error .ktc-checkbox label:after {
  border-color: #dc3545; }

.formbuilder-form .FieldLabel > .EditingFormLabel {
  font-size: 0.75rem;
  line-height: 1.16667;
  padding-left: 0.5rem;
  margin-bottom: 0.5rem; }
  @media screen and (min-width: 768px) {
    .formbuilder-form .FieldLabel > .EditingFormLabel {
      padding-left: 1.125rem; } }

/* radios */
.radio-list-vertical {
  margin-top: 0.3125rem;
  display: block; }

.formbuilder-form .radio,
.formbuilder-form .ktc-radio {
  cursor: pointer;
  font-size: 0.875rem;
  line-height: 1.46667;
  font-family: "EncodeSans-Light", Arial, sans-serif;
  font-weight: 400; }

.formbuilder-form .radio label,
.formbuilder-form .ktc-radio label {
  cursor: pointer;
  position: relative;
  margin-bottom: 0.5rem;
  padding-left: 1.875rem; }

.formbuilder-form .radio input[type="radio"],
.formbuilder-form .ktc-radio input[type="radio"] {
  position: absolute;
  width: 0.0625rem;
  height: 0.0625rem;
  margin: -0.0625rem;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.formbuilder-form .radio label:after,
.formbuilder-form .ktc-radio label:after {
  content: '';
  left: 0;
  top: 0;
  position: absolute;
  overflow: hidden;
  border: 0.0625rem solid #DCDCDC;
  background: #fff;
  width: 1.375rem;
  height: 1.375rem;
  border-radius: 50%; }

.formbuilder-form .radio label:before,
.formbuilder-form .ktc-radio label:before {
  content: '';
  position: absolute;
  top: 0.4375rem;
  left: 0.4375rem;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background: #5ac4be;
  visibility: hidden;
  z-index: 1; }

.formbuilder-form .radio input:checked + label:before,
.formbuilder-form .ktc-radio input:checked + label:before {
  visibility: visible; }

.formbuilder-form .radio input:not(:checked):focus + label:after,
.formbuilder-form .ktc-radio input:not(:checked):focus + label:after {
  border-color: #5ac4be; }

.formbuilder-form .has-error .radio label:after,
.formbuilder-form .has-error .ktc-radio label:after {
  border-color: #dc3545; }

/* form errors */
.formbuilder-form .parsley-errors-list {
  color: #CC0000; }

.formbuilder-form .parsley-errors-list:empty {
  display: none; }

.formbuilder-form .parsley-errors-list {
  list-style-type: none;
  padding: 0;
  margin: 0; }

.formbuilder-form .form-field {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  margin-bottom: 1.25rem; }

.formbuilder-form .form-field .control-label {
  width: 100%; }

.formbuilder-form .form-field .editing-form-control-nested-control {
  flex: 0 0 50%;
  max-width: 50%; }

.formbuilder-form .explanation-text {
  margin-left: 1rem; }

.formbuilder-form input[type="submit"] {
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid #0077c1;
  border-radius: 0;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  color: #fff;
  background-color: #0077c1;
  position: relative;
  font-family: "EncodeSans-Semibold", "Arial Black", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.15;
  padding: 0.875rem 1.125rem 0.8125rem;
  text-transform: uppercase;
  cursor: pointer; }
  @media screen and (min-width: 768px) {
    .formbuilder-form input[type="submit"] {
      font-size: 1.125rem;
      padding: 1rem 2rem 0.9375rem; } }

.formbuilder-form input[type="submit"]:hover,
.formbuilder-form input[type="submit"]:focus {
  color: #fff;
  background-color: #005f9b;
  border-color: #00588e; }

.link-box {
  padding: 1.75rem 1.5625rem 0.625rem 0.75rem;
  line-height: calc(22/17);
  max-width: 100%;
  position: relative;
  transition: color 200ms ease-in-out, background 200ms ease-in-out;
  height: 100%;
  font-size: 0.8125rem; }
  @media screen and (min-width: 768px) {
    .link-box {
      font-size: 1.0625rem;
      padding: 2.1875rem 2.8125rem 0.625rem 0.75rem; } }

.link-box__icon {
  position: absolute;
  right: 0.625rem;
  top: 0.625rem;
  font-size: 0.9375rem;
  transition: color 200ms ease-in-out;
  color: #5ac4be; }
  @media screen and (min-width: 768px) {
    .link-box__icon {
      font-size: 1.125rem; } }

.link-box:hover {
  background: #0077c1;
  color: #fff; }

.link-box:hover .link-box__icon {
  color: #fff; }

.cookie-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #212529;
  color: #ffffff;
  padding: .75rem 1rem;
  z-index: 100; }

@media screen and (min-width: 768px) {
  .cookie-bar {
    display: flex;
    align-items: baseline; } }

.cookie-bar__text {
  display: inline; }

@media screen and (min-width: 768px) {
  .cookie-bar__text {
    display: block;
    flex: 0 1 auto; } }

.cookie-bar__detail,
a.cookie-bar__detail {
  display: inline; }

@media screen and (min-width: 768px) {
  .cookie-bar__detail {
    flex: auto;
    margin-left: 1rem; } }

.cookie-bar__detail-btn {
  display: inline;
  text-decoration: underline;
  vertical-align: inherit;
  cursor: pointer;
  margin-right: .25em; }

.cookie-bar__buttons {
  margin-top: .75rem;
  text-align: center; }

@media screen and (min-width: 768px) {
  .cookie-bar__buttons {
    flex: none;
    margin-top: 0;
    margin-left: 1rem;
    text-align: right; } }

.cookie-bar__accept {
  margin-left: .75rem; }

/* modal */
.cookie-modal__item {
  margin-bottom: .75rem; }

.cookie-modal__item--all {
  margin-bottom: 1rem; }

.cookie-modal__label {
  font-size: 1.5rem; }

.cookie-modal__link {
  display: inline-block;
  margin-left: .5rem;
  text-decoration: underline; }

/* switch */
.cookie-modal__switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  vertical-align: middle;
  margin-right: .5rem; }

.cookie-modal__switch-toggle {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px; }

.cookie-modal__switch-toggle:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 34px; }

input:checked + .cookie-modal__switch-toggle {
  background-color: #0077c1; }

input:focus + .cookie-modal__switch-toggle {
  box-shadow: 0 0 2px #0077c1; }

input:checked + .cookie-modal__switch-toggle:before {
  transform: translateX(26px); }

.tooltip-inner {
  background: #fff;
  border: 0.0625rem solid #5ac4be;
  font-size: 0.75rem;
  line-height: calc(14/12);
  font-family: "EncodeSans-Light", Arial, sans-serif;
  font-weight: 400;
  color: #282828;
  max-width: 18.75rem;
  padding: 0.625rem 0.75rem; }

.tooltip__arrow {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 0.75rem;
  width: 0.375rem; }

.tooltip__arrow:before,
.tooltip__arrow:after {
  content: "";
  position: absolute;
  border-color: transparent;
  border-style: solid;
  z-index: 1;
  border-width: .4rem .4rem .4rem 0;
  border-right-color: #5ac4be; }

.tooltip__arrow:after {
  border-right-color: #fff;
  right: -0.0625rem; }

/* bottom tooltip */
.bs-tooltip-auto[x-placement^=bottom] .tooltip__arrow, .bs-tooltip-bottom .tooltip__arrow, .bs-tooltip-auto[x-placement^="bottom"] .tooltip__arrow {
  top: -0.125rem;
  left: 50%;
  transform: translateX(-50%) rotate(90deg); }

/* top tooltip */
.bs-tooltip-auto[x-placement^=top] .tooltip__arrow, .bs-tooltip-top .tooltip__arrow, .bs-tooltip-auto[x-placement^="top"] .tooltip__arrow {
  top: auto;
  bottom: -0.125rem;
  left: 50%;
  transform: translateX(-50%) rotate(-90deg); }

/* left tooltip */
.bs-tooltip-auto[x-placement^=left] .tooltip__arrow, .bs-tooltip-left .tooltip__arrow, .bs-tooltip-auto[x-placement^="left"] .tooltip__arrow {
  left: auto;
  right: 0;
  transform: translateY(-50%) rotate(-180deg); }

.compare-list {
  font-size: 0.75rem; }
  @media screen and (min-width: 768px) {
    .compare-list {
      font-size: 1.25rem; } }
  @media screen and (max-width: 767px) {
    .compare-list {
      margin: 0 -0.9375rem; } }

/* top head bar */
.compare-list__legend {
  padding: 0.625rem;
  font-size: 0.875rem; }
  @media screen and (min-width: 768px) {
    .compare-list__legend {
      font-size: 1.125rem;
      padding: 1.125rem 1.25rem; } }

/* left head bar */
.compare-list__main-head {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%; }

.compare-list__main {
  position: relative; }

@media screen and (min-width: 768px) {
  .is-slider .compare-list__main {
    padding-right: 3.4375rem; } }

/* slider arrow */
/* todo  */
@media screen and (max-width: 767px) {
  .compare-list__main__nav {
    display: none !important; } }

.compare-list__main__nav.slick-arrow {
  position: absolute;
  top: 0;
  right: 0;
  transform: none;
  font-size: 1.125rem;
  padding: 1.875rem 0.625rem;
  left: auto;
  bottom: 0;
  background: #5ac4be;
  width: 3.4375rem;
  transition: background 200ms ease-in-out; }

.compare-list__main__nav.slick-arrow:hover {
  color: #fff;
  background: #41b6b0; }

.compare-list__main__nav__text {
  position: absolute;
  left: 1.0625rem;
  top: 1.875rem;
  transform-origin: left top;
  transform: rotate(-90deg) translateX(-100%); }

.compare-list__main__nav__icon {
  transform: rotate(90deg);
  margin-left: 1.25rem;
  vertical-align: -0.1em; }

/* cols */
.compare-list-col {
  padding: 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-top: 0.0625rem solid #DCDCDC;
  border-right: 0.0625rem solid #DCDCDC;
  position: relative; }
  @media screen and (min-width: 768px) {
    .compare-list-col {
      padding: 1.375rem 1.25rem; } }

.compare-list-col__content {
  max-height: 5rem;
  overflow: hidden; }
  @media screen and (min-width: 768px) {
    .compare-list-col__content {
      max-height: 5.3125rem; } }

.is-expanded .compare-list-col__content {
  overflow: visible; }

.compare-list__main-head .compare-list-col:nth-child(odd),
.compare-list__main .compare-list-col:nth-child(odd) {
  background: #f4f4f4; }

.compare-list__main-head__col {
  font-size: 0.75rem;
  word-break: break-word; }
  @media screen and (min-width: 768px) {
    .compare-list__main-head__col {
      font-size: 1.25rem; } }

.compare-list__main-head__nav {
  flex-grow: 1; }
  @media screen and (min-width: 768px) {
    .compare-list__main-head__nav {
      height: 65px; } }

.compare-list__main-head__nav .slick-arrow.compare-list__main-head__nav__btn {
  left: auto;
  right: auto;
  position: relative;
  font-size: 0.875rem;
  height: 100%;
  transform: none;
  top: 0;
  background: #5ac4be;
  color: #fff;
  padding: 0.3125rem; }
  @media screen and (min-width: 768px) {
    .compare-list__main-head__nav .slick-arrow.compare-list__main-head__nav__btn {
      font-size: 1.5rem;
      height: 65px; } }

.slick-arrow.compare-list__main-head__nav__btn:hover,
.slick-arrow.compare-list__main-head__nav__btn:focus {
  color: #fff;
  background: #3eada7;
  outline: none; }

.slick-arrow.slick-disabled.compare-list__main-head__nav__btn {
  opacity: 1;
  background: #C6C6C6;
  color: #f4f4f4; }

/* toggle button  */
.compare-list-col__more {
  position: absolute;
  bottom: 0.3125rem;
  right: 0.3125rem;
  display: none;
  font-size: 0.875rem; }
  @media screen and (min-width: 768px) {
    .compare-list-col__more {
      font-size: 1.25rem;
      bottom: 0.75rem;
      right: 0.625rem; } }

.is-expandable .compare-list-col__more,
.is-expanded .compare-list-col__more {
  display: block; }

.is-expanded .compare-list-col__more:before {
  content: ""; }

/* compare list large cols */
@media screen and (min-width: 768px) {
  .compare-list--lg {
    font-size: 1.0625rem;
    line-height: calc(28/17); } }

.compare-list--lg .compare-list-col:not(.compare-list__main-head__col) {
  justify-content: flex-start; }

.download-item {
  background: #f4f4f4;
  border-top: 0.125rem solid #DCDCDC;
  padding-left: 0.9375rem; }
  @media screen and (min-width: 768px) {
    .download-item {
      padding-left: 3rem; } }

.download-item + .download-item {
  margin-top: 0.75rem; }

.download-item__title {
  padding: 0.3125rem 0;
  font-size: 1rem;
  line-height: calc(24/21); }
  @media screen and (min-width: 768px) {
    .download-item__title {
      font-size: 1.3125rem; } }

.download-item__btn {
  margin-top: -0.0625rem;
  height: 3.75rem;
  padding: 0.3125rem 0.625rem;
  align-items: center;
  display: flex;
  text-align: center; }
  @media screen and (min-width: 786px) {
    .download-item__btn {
      padding: 0.3125rem 2.5rem;
      height: 5.3125rem; } }

.download-item__btn__icon {
  font-size: 1.125rem;
  position: relative; }
  @media screen and (min-width: 786px) {
    .download-item__btn__icon {
      font-size: 1.875rem; } }

.download-item__btn--locked .download-item__btn__icon:after {
  content: "";
  position: absolute;
  top: -0.4375rem;
  right: -0.625rem;
  font-size: 0.75rem; }
  @media screen and (min-width: 768px) {
    .download-item__btn--locked .download-item__btn__icon:after {
      font-size: 1.0625rem;
      right: -1.125rem; } }

/* download lang select */
.download-item__select {
  height: 3.6875rem; }
  @media screen and (min-width: 768px) {
    .download-item__select {
      height: 5.25rem; } }

.download-item__select select {
  font-size: 0.875rem;
  padding: 0.3125rem 0.75rem 0.3125rem 0.3125rem;
  font-family: "EncodeSans-Light", Arial, sans-serif;
  font-weight: 400; }
  @media screen and (min-width: 768px) {
    .download-item__select select {
      font-size: 1.125rem;
      padding: 0.3125rem 1.5625rem; } }

.download-item__select select:focus {
  background: #DCDCDC;
  color: #282828; }

.download-item__select:after {
  color: #282828;
  right: 0rem;
  font-size: 0.4375rem; }
  @media screen and (min-width: 768px) {
    .download-item__select:after {
      font-size: 0.75rem;
      right: 1.875rem; } }

.download-item__language {
  font-size: 1.5rem;
  line-height: 1.64; }

/* group */
.download-item-group + .download-item-group {
  margin-top: 2.8125rem; }

.data-row {
  position: relative; }
  @media screen and (min-width: 768px) {
    .data-row {
      border-top: 0.0625rem solid #DCDCDC; } }

@media screen and (min-width: 768px) {
  .data-row:nth-child(odd) {
    background: #f4f4f4; }
  .data-row:nth-child(odd) .expandable-block__more,
  .data-row:nth-child(odd) .expandable-block__less {
    background: #f4f4f4; } }

@media screen and (max-width: 767px) {
  .data-row + .data-row {
    margin-top: 1.25rem; } }

.data-row__date {
  padding: 10px;
  height: 100%;
  min-height: 1px; }
  @media screen and (min-width: 768px) {
    .data-row__date {
      padding: 0;
      width: 2.8125rem; } }

@media screen and (min-width: 768px) {
  .data-row__date__text {
    position: absolute;
    left: 50%;
    top: 50%;
    transform-origin: left top;
    transform: rotate(-90deg) translateX(-50%) translateY(-50%); } }

.data-row__col {
  padding: 0.625rem;
  height: 100%;
  border: 0.0625rem solid #DCDCDC;
  border-top: 0; }
  @media screen and (min-width: 768px) {
    .data-row__col {
      padding: 1.125rem;
      border: 0;
      border-left: 0.0625rem solid #DCDCDC;
      display: flex;
      align-items: center; } }

.data-row__col--lg {
  font-size: 1rem;
  line-height: calc(28/20); }
  @media screen and (min-width: 768px) {
    .data-row__col--lg {
      font-size: 1.25rem; } }

@media screen and (max-width: 767px) {
  .data-row__col--title {
    font-family: "EncodeSans-Semibold", "Arial Black", sans-serif;
    font-weight: 400; } }

.data-sorting__col {
  border-left: 1px solid #DCDCDC; }

.data-row__link-icon {
  font-size: 0.875rem;
  width: 0.9375rem;
  vertical-align: -.15em; }

.data-sorting__title {
  padding: 20px 25px;
  font-size: 18px;
  line-height: 22px; }

/* sort buttons */
.data-sorting__sort {
  width: 45px;
  font-size: 20px;
  padding: 10px 5px;
  line-height: 1;
  height: 100%; }

.data-sorting__sort:hover,
.data-sorting__sort.is-active {
  background: #5ac4be; }

.expandable-block {
  position: relative;
  overflow: hidden; }

.expandable-block.is-collapsed {
  display: block;
  height: 95px;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  @media screen and (min-width: 768px) {
    .expandable-block.is-collapsed {
      height: 6.875rem; } }

.expandable-block__content {
  position: relative;
  margin-bottom: 0.625rem;
  margin-right: 0.625rem; }

.expandable-block.is-collapsed .expandable-block__content {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border: 0; }

.expandable-block__more,
.expandable-block__less {
  position: absolute;
  bottom: -0.625rem;
  right: -0.625rem;
  visibility: hidden;
  z-index: 1;
  line-height: 1;
  background: #fff; }

.expandable-block.is-expandable.is-collapsed .expandable-block__more,
.expandable-block.is-expandable.is-collapsed:after {
  visibility: visible; }

.expandable-block.is-expandable .expandable-block__less {
  visibility: visible; }

.expandable-block.is-expandable.is-collapsed .expandable-block__less {
  visibility: hidden; }

/*is-not-expandable*/
.expandable-block.is-not-expandable {
  height: auto;
  overflow: visible; }

.expandable-block.is-not-expandable .expandable-block__content {
  position: static;
  height: auto;
  width: auto; }

.expandable-block.is-not-expandable .expandable-block__more,
.expandable-block.is-not-expandable .expandable-block__less {
  display: none; }

@media screen and (min-width: 768px) {
  .expandable-block.is-not-expandable {
    height: auto;
    padding-top: 0; } }

.expandable-block--bg-secondary:after {
  background: linear-gradient(to bottom, rgba(80, 116, 47, 0) 0%, var(--color-secondary) 50%); }

/* slider */
.slick-dots {
  position: absolute;
  top: 0;
  left: 50%;
  list-style: none;
  text-align: right;
  max-width: 92.5rem;
  width: 100%;
  transform: translateX(-50%);
  padding: 0 1.25rem; }

.slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0.9375rem;
  z-index: 1;
  font-size: 1.5rem;
  color: #fff;
  transition: color 200ms ease-in-out, opacity 200ms ease-in-out;
  line-height: 1;
  padding: 0.9375rem 0; }
  @media screen and (min-width: 768px) {
    .slick-arrow {
      font-size: 2.125rem;
      left: 1.875rem; } }
  @media screen and (min-width: 1200px) {
    .slick-arrow {
      left: 3.125rem; } }

.slick-arrow:hover {
  color: #5ac4be; }

.slick-arrow.slick-next {
  left: auto;
  right: 0.9375rem; }
  @media screen and (min-width: 768px) {
    .slick-arrow.slick-next {
      left: auto;
      right: 1.875rem; } }
  @media screen and (min-width: 1200px) {
    .slick-arrow.slick-next {
      left: auto;
      right: 3.125rem; } }

.slick-arrow.slick-disabled {
  opacity: 0;
  pointer-events: none; }

.slick-dots li {
  display: inline-block; }

.slick-dots li + li {
  margin-left: 0.1875rem; }

.slick-dots button {
  border: none;
  font-size: 0;
  color: transparent;
  padding: 0;
  cursor: pointer;
  width: 3.75rem;
  height: 0.3125rem;
  background: #fff;
  position: relative; }

.slick-dots button:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #5ac4be;
  transform: scaleY(0);
  transform-origin: bottom center;
  transition: transform 250ms ease-in-out; }

.slick-dots .slick-active button:before {
  transform: scaleY(1); }

.usp-slider {
  background: url(/static/img/backgrounds/maco/gradient-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative; }

.usp-slider .slick-track {
  display: flex; }

.usp-slider .slick-slide {
  display: flex;
  height: auto;
  justify-content: center; }

.usp-slider__head {
  padding: 0.9375rem 0.9375rem 0; }
  @media screen and (min-width: 768px) {
    .usp-slider__head {
      padding: 3.875rem 0 0; } }

.usp-slider__item {
  padding: 0.9375rem;
  position: relative; }
  @media screen and (min-width: 768px) {
    .usp-slider__item {
      padding: 3.4375rem 3.125rem; } }

.usp-slider__item__icon {
  font-size: 5.625rem; }
  @media screen and (min-width: 768px) {
    .usp-slider__item__icon {
      font-size: 8.125rem; } }

.usp-slider__item__img {
  display: block;
  margin: 0 auto;
  width: auto;
  height: 90px; }
  @media screen and (min-width: 768px) {
    .usp-slider__item__img {
      height: 130px; } }

.usp-slider__item__title {
  margin-top: 1.25rem;
  margin-bottom: 0.5rem;
  font-size: 1.75rem; }
  @media screen and (min-width: 768px) {
    .usp-slider__item__title {
      font-size: 2rem;
      margin-top: 4.6875rem;
      margin-bottom: 0.75rem; } }

.usp-slider__item__text {
  flex-grow: 1; }

@media screen and (min-width: 768px) {
  .usp-slider .slick-arrow {
    left: 1.25rem; }
  .usp-slider .slick-arrow.slick-next {
    left: auto;
    right: 1.25rem; } }

.usp-slider .slick-arrow:hover {
  color: #fff; }

.usp-slider__item__more {
  position: absolute;
  bottom: 3.125rem;
  left: 50%;
  transform: translateX(-50%); }

.teaser-slider {
  overflow: hidden; }
  @media screen and (min-width: 768px) {
    .teaser-slider {
      padding: 0 4.0625rem; } }
  @media screen and (min-width: 1200px) {
    .teaser-slider {
      padding: 0 7.1875rem; } }

.teaser-slider .slick-list {
  overflow: visible; }

.teaser-slider .slick-slide {
  opacity: .6;
  transition: opacity 200ms ease-in-out;
  pointer-events: none; }
  @media screen and (min-width: 768px) {
    .teaser-slider .slick-slide {
      padding: 0 0.625rem; } }
  @media screen and (min-width: 1400px) {
    .teaser-slider .slick-slide {
      padding: 0 1.5625rem; } }

.teaser-slider .slick-active {
  opacity: 1;
  pointer-events: auto; }

.thumb-slider {
  font-size: 0;
  /* fix slick slider height bug */ }

.thumb-slider__main__item {
  margin: 0; }

.thumb-slider__thumbs__item {
  margin: 0;
  margin-bottom: 0.3125rem;
  position: relative; }

.thumb-slider__thumbs__item:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #000;
  opacity: .3;
  transition: opacity 200ms ease-in-out;
  z-index: 1; }

.slick-current .thumb-slider__thumbs__item:before {
  opacity: 0; }

.thumb-slider__thumbs__item:after {
  content: '';
  position: absolute;
  top: 0.375rem;
  bottom: 0.375rem;
  left: 0.375rem;
  right: 0.375rem;
  border: 0.0625rem solid #5ac4be;
  transition: opacity 200ms ease-in-out;
  opacity: 0; }

.slick-current .thumb-slider__thumbs__item:after {
  opacity: 1; }

@media screen and (min-width: 768px) {
  .thumb-slider .slick-arrow {
    left: 50%;
    transform: translateX(-50%) translateY(-50%) rotate(90deg);
    top: 0;
    color: #5ac4be;
    font-size: 2.125rem;
    width: 2.5rem;
    height: 2.875rem;
    padding: 0; } }

@media screen and (min-width: 768px) {
  .thumb-slider .slick-arrow:before {
    content: "";
    font-family: iconfont;
    color: #fff;
    position: absolute;
    transform: rotate(90deg);
    z-index: 1;
    font-size: 0.625rem;
    left: 0.5rem;
    top: 0.9375rem; } }

@media screen and (min-width: 768px) {
  .thumb-slider .slick-arrow.slick-next {
    right: auto;
    top: auto;
    left: 50%;
    bottom: 0.3125rem;
    transform: translateX(-50%) translateY(50%) rotate(-90deg); } }

@media screen and (max-width: 767px) {
  .thumb-slider .slick-arrow {
    top: 0;
    margin-top: 30%; } }

/* teaser */
.img-teaser {
  position: relative; }

.img-teaser:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #000;
  opacity: .6;
  transition: opacity 300ms ease-in-out; }

.img-teaser:hover:after {
  opacity: 0; }

.img-teaser__content {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
  padding: 1.25rem 5.3125rem 1.25rem 2.25rem; }

.img-teaser__content__title {
  font-size: 1.125rem;
  line-height: 1.23077; }
  @media screen and (min-width: 1200px) {
    .img-teaser__content__title {
      font-size: 1.5rem; } }
  @media screen and (min-width: 1400px) {
    .img-teaser__content__title {
      font-size: 1.625rem; } }

.img-teaser__content__date {
  position: relative;
  padding-bottom: 0.125rem; }

.img-teaser__content__date:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 3.4375rem;
  height: 0.0625rem;
  background: #fff; }

.teaser__badge {
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  transform: rotate(-90deg) translateX(-100%);
  transform-origin: top left;
  padding: 0.4375rem 1.25rem;
  font-size: 0.9375rem;
  line-height: 1.125rem; }

.news-teaser {
  font-size: 0.9375rem;
  line-height: 1.6; }

.news-teaser__head {
  position: relative;
  margin-bottom: 0.75rem; }

.news-teaser__head:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #000;
  opacity: .6;
  transition: opacity 300ms ease-in-out; }

.news-teaser:hover .news-teaser__head:after {
  opacity: 0; }

.news-teaser__head__content {
  z-index: 1;
  padding: 0.9375rem 3.125rem;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0; }
  @media screen and (max-width: 1199px) {
    .news-teaser__head__content {
      padding: 0.3125rem 0 0.3125rem 2.5rem; } }

.news-teaser__head__content__title {
  font-size: 1.375rem;
  line-height: 1.27273; }
  @media (min-width: 768px) and (max-width: 1199px) {
    .news-teaser__head__content__title {
      font-size: 1.125rem; } }
  @media screen and (max-width: 767px) {
    .news-teaser__head__content__title {
      font-size: 1.375rem; } }

.news-teaser__additional-info li {
  display: inline-block;
  position: relative; }

.news-teaser__additional-info li:not(:last-child) {
  padding-right: 0.9375rem; }

.news-teaser__additional-info li:not(:last-child):before {
  content: '';
  position: absolute;
  right: 0.25rem;
  top: 50%;
  transform: translateY(-50%);
  width: 0.1875rem;
  height: 0.1875rem;
  border-radius: 50%;
  background: #3B3B39; }

.nav-teaser__title {
  background: #696969; }

.nav-teaser__dropdown {
  background-position: center;
  background-size: cover; }

.nav-teaser__overlay {
  background-color: rgba(0, 0, 0, 0.2); }

.nav-teaser__dropdown li {
  display: block !important; }

.link-unstyled:hover {
  color: #FFF !important; }

.horizontal-teaser {
  font-size: 0.9375rem;
  line-height: 1.6; }

.horizontal-teaser__body {
  margin-top: 0.625rem; }
  @media screen and (min-width: 768px) {
    .horizontal-teaser__body {
      padding-left: 1.375rem;
      margin-top: -0.25rem; } }

.horizontal-teaser__body__doctype {
  font-size: 0.9375rem; }

/* Utility & Helper Classes (mostly atomic classes; high specificity; eg: mt10; fz20)*/
/*Margin Helper*/
/*Padding Helper*/
/*Text Helper*/
.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

/*Text Color Helper*/
.text-primary-hover:hover, a.text-primary {
  color: #0077c1 !important; }

a.text-primary:hover {
  color: #00588e !important; }

.text-secondary-hover:hover, .text-secondary-hover:focus {
  color: #5ac4be; }

.text-white {
  color: #ffffff; }

.text-color-default {
  color: #282828; }

.text-orange {
  color: #FF7100; }

.text-brown {
  color: #A66E00; }

.text-grey-dark {
  color: #696969; }

.text-gold {
  color: #C09B57; }

.text-poi-1 {
  color: #0077c1; }

.text-poi-2 {
  color: #FF7100; }

.text-poi-3 {
  color: #5ac4be; }

.text-poi-4 {
  color: #A66E00; }

.text-poi-5 {
  color: #5A3EB9; }

.text-poi-6 {
  color: #CC0000; }

.text-poi-7 {
  color: #056383; }

.text-poi-8 {
  color: #D16B00; }

.text-poi-9 {
  color: #5BA7C1; }

.text-poi-10 {
  color: #884600; }

/*Background Color Helper*/
.bg-default {
  background: #3B3B39;
  color: #fff; }

.bg-primary {
  color: #fff; }

.bg-secondary {
  color: #fff; }

.bg-gradient {
  background: linear-gradient(180deg, #EEEEEE 0%, rgba(238, 238, 238, 0) 100%); }

.bg-grey {
  background: #DCDCDC; }

.bg-grey-light {
  background: #f4f4f4; }

.isClickable {
  cursor: pointer; }

.link-with-icon__icon {
  font-size: 2.5rem;
  line-height: 1.03; }
  @media screen and (min-width: 768px) {
    .link-with-icon__icon {
      font-size: 3.125rem; } }

.content-box {
  padding: 0.9375rem;
  font-size: 0.75rem; }
  @media screen and (min-width: 768px) {
    .content-box {
      padding: 2.8125rem 3.625rem; } }
