.download-item {
    background: $color-grey-light;
    border-top: rem(2) solid $color-grey;
    padding-left: rem(15);

    @media screen and (min-width: 768px) {
        padding-left: rem(48);
    }
}
.download-item + .download-item {
    margin-top: rem(12);
}
.download-item__title {
    padding: rem(5) 0;
    font-size: rem(16);
    line-height: calc(24/21);

    @media screen and (min-width: 768px) {
        font-size: rem(21);
    }
}
.download-item__btn {
    margin-top: rem(-1);
    height: rem(60);
    padding: rem(5) rem(10);
    align-items: center;
    display: flex;
    text-align: center;

    @media screen and (min-width: 786px){
        padding: rem(5) rem(40);
        height: rem(85);
    }
}
.download-item__btn__icon {
    font-size: rem(18);
    position: relative;

    @media screen and (min-width: 786px){
        font-size: rem(30);
    }
}
.download-item__btn--locked .download-item__btn__icon:after{
    content: $icon-lock;
    position: absolute;
    top: rem(-7);
    right: rem(-10);
    font-size: rem(12);

    @media screen and (min-width: 768px){
        font-size: rem(17);
        right: rem(-18);
    }
}
/* download lang select */
.download-item__select{
    height: rem(59);

    @media screen and (min-width: 768px) {
        height: rem(84);
    }
}
.download-item__select select {
    font-size: rem(14);
    padding: rem(5) rem(12) rem(5) rem(5);
    font-family: $font-default;
    font-weight: 400;

    @media screen and (min-width: 768px) {
        font-size: rem(18);
        padding: rem(5) rem(25);
    }
}
.download-item__select select:focus {
    background: $color-grey;
    color: $color-default;
}
.download-item__select:after {
    color: $color-default;
    right: rem(0);
    font-size: rem(7);

    @media screen and (min-width: 768px) {
        font-size: rem(12);
        right: rem(30);
    }
}

.download-item__language {
    font-size: rem(24);
    line-height: 1.64;
}

/* group */
.download-item-group + .download-item-group {
    margin-top: rem(45);
}