.icon-btn {
    position: relative;
    width: 50px;
    height: 50px;
    padding: 5px;
}
.icon-btn__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}
.icon-btn__icon.icon-rotate-180 {
    transform: translateX(-50%) translateY(-50%) rotate(180deg);
}