.console {
  position: fixed;
  bottom: rem(15);
  left: rem(15);
  right: rem(15);
  opacity: 0;
  transform: translateY(100%);
  transition: transform 200ms ease-in-out, opacity 200ms ease-in-out;
    z-index: 10;

  @media screen and (min-width: 768px) {
      left: auto;
      bottom: 0;
      right: 0;
  }
}
.console.is-affix {
  opacity: 1;
  transform: translateY(0);
}
.console.is-affix-bottom {
  position: absolute;
}
.console li + li .console__btn {
    margin-left: rem(10);

    @media screen and (min-width: 768px) {
        margin-left: 0;
        border-left: rem(1) solid #fff;
    }
}
.console__btn {
  padding: rem(12);
  font-size: rem(12);
  line-height: (18/15);
  background: $primary;
  color: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
    box-shadow: 0 0 rem(5) rgba(0, 0, 0, 0.2);

  @media screen and (min-width: 768px) {
      box-shadow: none;
    font-size: rem(15);
    width: rem(120);
    height: rem(120);
  }
}
.console__btn__icon {
  font-size: rem(22);
  display: block;

  @media screen and (min-width: 768px) {
    font-size: rem(30);
    margin-bottom: rem(12);
  }
}
.console__btn:hover,
.console__btn:focus {
  color: #fff;
  background: darken($primary, 7.5%);
}