.scrollspy {
    background: $primary;
    color: #fff;
    width: 35px;
    position: fixed;
    left: 0;
    top: 36%;
    z-index: 10;
    border-radius: 6px;
}
.scrollspy:before,
.scrollspy:after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 24px 0 24px 35px;
    border-color: transparent transparent transparent $primary;
    position: absolute;
    top: -22px;
    left: -1px;
}
.scrollspy:after {
    top: auto;
    bottom: -22px;
}
.scrollspy li + li {
    margin-top: 23px;
}
.scrollspy__item {
    position: relative;
    padding: 0 5px;
    font-size: 12px;
    text-align: center;
    z-index: 1;
    font-family: $font-default-bold;
    font-weight: 400;
    opacity: .5;
    transition: opacity 200ms ease-in-out;
}
.scrollspy__item.active {
    opacity: 1;
}
.scrollspy__item__text {
    position: absolute;
    left: 100%;
    top: 50%;
    transform: translateY(-50%) translateX(4px);
    background: $secondary;
    font-family: $font-default-bold;
    font-weight: 400;
    opacity: 0;
    transition: opacity 200ms ease-in-out;
    padding: 2px 8px;
    line-height: 1.2;
}
.active .scrollspy__item__text,
.scrollspy__item:hover .scrollspy__item__text {
    opacity: 1;
}