.expandable-block {
    position: relative;
    overflow: hidden;
}
.expandable-block.is-collapsed {
    display: block;
    height: 95px;
    width: 100%;
    padding: 0;
    overflow: hidden;

    @media screen and (min-width: 768px) {
        height: rem(110);
    }
}
.expandable-block__content {
    position: relative;
    margin-bottom: rem(10);
    margin-right: rem(10);
}
.expandable-block.is-collapsed .expandable-block__content {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border: 0;
}
.expandable-block__more,
.expandable-block__less {
    position: absolute;
    bottom: rem(-10);
    right: rem(-10);
    visibility: hidden;
    z-index: 1;
    line-height: 1;
    background: #fff;
}
.expandable-block.is-expandable.is-collapsed .expandable-block__more,
.expandable-block.is-expandable.is-collapsed:after {
    visibility: visible;
}
.expandable-block.is-expandable .expandable-block__less {
    visibility: visible;
}
.expandable-block.is-expandable.is-collapsed .expandable-block__less {
    visibility: hidden;
}
/*is-not-expandable*/
.expandable-block.is-not-expandable {
    height: auto;
    overflow: visible;
}
.expandable-block.is-not-expandable .expandable-block__content {
    position: static;
    height: auto;
    width: auto;
}
.expandable-block.is-not-expandable .expandable-block__more,
.expandable-block.is-not-expandable .expandable-block__less {
    display: none;
}
@media screen and (min-width: 768px) {
    .expandable-block.is-not-expandable {
        height: auto;
        padding-top: 0;
    }
}

.expandable-block--bg-secondary:after {
    background: linear-gradient(to bottom, rgba(80, 116, 47, 0) 0%, var(--color-secondary) 50%);
}
