.tooltip-inner {
    background: #fff;
    border: rem(1) solid $secondary;
    font-size: rem(12);
    line-height: calc(14/12);
    font-family: $font-default;
    font-weight: 400;
    color: $default-text-color;
    max-width: rem(300);
    padding: rem(10) rem(12);
}
.tooltip__arrow {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    height: rem(12);
    width: rem(6);
}
.tooltip__arrow:before,
.tooltip__arrow:after {
    content: "";
    position: absolute;
    border-color: transparent;
    border-style: solid;
    z-index: 1;
    border-width: .4rem .4rem .4rem 0;
    border-right-color: $secondary;
}
.tooltip__arrow:after {
    border-right-color: #fff;
    right: rem(-1);
}

/* bottom tooltip */
.bs-tooltip-auto[x-placement^=bottom] .tooltip__arrow, .bs-tooltip-bottom .tooltip__arrow {
    top: rem(-2);
    left: 50%;
    transform: translateX(-50%) rotate(90deg);
}
/* top tooltip */
.bs-tooltip-auto[x-placement^=top] .tooltip__arrow, .bs-tooltip-top .tooltip__arrow {
    top: auto;
    bottom: rem(-2);
    left: 50%;
    transform: translateX(-50%) rotate(-90deg);
}
/* left tooltip */
.bs-tooltip-auto[x-placement^=left] .tooltip__arrow, .bs-tooltip-left .tooltip__arrow {
    left: auto;
    right: 0;
    transform: translateY(-50%) rotate(-180deg);
}