.horizontal-teaser {
  font-size: rem(15);
  line-height: (24/15);
}
.horizontal-teaser__body{
  margin-top: rem(10);

  @media screen and (min-width: 768px) {
    padding-left: rem(22);
    margin-top: rem(-4);
  }
}
.horizontal-teaser__body__doctype {
    font-size: rem(15);
}