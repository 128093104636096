.page-link {
  color: $default-text-color;
  font-size: rem(15);
  line-height: calc(22/15);
  padding: rem(8) rem(10);
  border-bottom: 1px solid $color-grey;
}
.page-item.active .page-link {
  font-family: $font-default-bold;
  font-weight: $font-default-weight;
  color: $default-text-color;
}
.page-item {
  padding: 0 rem(6);
}
.page-item--arrow:last-child {
  margin-left: rem(22);
}
.page-item--arrow:first-child {
  margin-right: rem(22);
}
.page-item--arrow .page-link {
  border: none;
  color: $secondary;
}