.figure {
    position: relative;
}
.figure__caption {
    position: relative;
    background: rgba(0,0,0,.5);
    color: #fff;
    font-size: rem(14);
    line-height: (18/14);
    padding: rem(10) rem(40) rem(10) rem(10);

    @media screen and (min-width: 768px) {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding: rem(20) rem(60) rem(20) rem(24);
    }
}
.figure__caption__expand {
    position: absolute;
    top: 50%;
    right: rem(10);
    font-size: rem(26);
    transform: translateY(-50%);

    @media screen and (min-width: 768px) {
        right: rem(20);
        font-size: rem(30);
    }
}

.figure__btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) rotate(90deg);
    z-index: 1;
    color: #fff;
    font-size: rem(70);
    cursor: pointer;

    @media screen and (min-width: 768px) {
        font-size: rem(118);
    }
}
.figure__btn:after {
    content: $icon-hexagon;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    color: $secondary;
    font-size: rem(60);
    transition: color 200ms ease-in-out;

    @media screen and (min-width: 768px) {
        font-size: rem(102);
    }
}
.figure__btn__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) rotate(-90deg);
    font-size: rem(24);
    z-index: 1;

    @media screen and (min-width: 768px) {
        font-size: rem(34);
    }
}
.figure__btn__icon.icon-play {
    margin-top: rem(-4);
}
.figure:hover .figure__btn:after,
.figure__btn:hover:after {
    color: $primary;
}