.language-item {
  border-bottom: rem(1) solid $color-grey;
  font-size: rem(15);
  padding: rem(7) rem(15) rem(7) rem(6);
}
.language-item__link:not(:last-child) {
  margin-right: rem(8);
}
.language-item__link:hover {
  text-decoration: underline;
}
.language-item__link.is-active {
  color: #999999;
  pointer-events: none;
}