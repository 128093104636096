.custom-radio {
    cursor: pointer;
    font-size: rem(14);
    line-height: (22/15);
    font-family: $font-default;
    font-weight: $font-default-weight;
}
.custom-radio + .custom-radio {
    margin-top: rem(12);
}
.custom-radio:before,
.custom-radio:after {
    content: " ";
    display: table;
}.custom-radio:after {
     clear: both;
 }
.custom-radio__input {
    position: absolute;
    width: rem(1);
    height: rem(1);
    margin: rem(-1);
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}
.custom-radio__box {
    position: relative;
    float: left;
    margin-right: rem(12);
    overflow: hidden;
    border: rem(1) solid $color-grey;
    background: $color-grey;
    width: rem(22);
    height: rem(22);
    border-radius: 50%;
}
.custom-radio__box:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: rem(8);
    height: rem(8);
    border-radius: 50%;
    background: $secondary;
    visibility: hidden;
}
.custom-radio__input:checked ~ .custom-radio__box:before {
    visibility: visible;
}
.custom-checkbox__input:not(:checked):focus ~ .custom-radio__box {
    border-color: $primary;
}
.has-error .custom-radio__box {
    border-color: $danger
}
.custom-radio__text {
    display: block;
    overflow: hidden;
}

.custom-radio--white .custom-radio__box {
    background: #fff;
    border-color: #fff;
    box-shadow: 0 rem(-1) 0 0 $color-grey;
}

/* radio text style */
.custom-radio--text {
    font-family: $font-default;
    font-size: rem(15);
    text-transform: uppercase;
}
.custom-radio--text label {
    cursor: pointer;
}
.custom-radio--text .custom-radio__input:checked ~ .custom-radio__text,
.custom-radio--text:hover {
    color: $secondary;
}
.custom-radio--text .custom-radio__text {
    position: relative;
    padding-bottom: rem(6);
}
.custom-radio--text .custom-radio__text:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: $secondary;
    height: rem(2);
    transform: scaleX(0);
    transform-origin: center;
    transition: transform 200ms ease-in-out;
}
.custom-radio--text .custom-radio__input:checked ~ .custom-radio__text:after{
    transform: scaleX(1);
}

.radio-list .list-inline-item:not(:last-child) {
    margin-right: rem(30);

    @media screen and (min-width: 768px) {
        margin-right: rem(70);
    }
}