.pre-footer {
  position: relative;
  padding: rem(35) 0 rem(70);

    @media screen and (min-width: 768px) {
        padding: rem(50) 0 rem(80);
    }
}
.pre-footer__bg {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 100%;
  max-height: rem(200);
  z-index: -1;

    @media screen and (min-width: 768px) {
        max-width: 90%;
        height: auto;
        max-height: none;
    }

    @media screen and (min-width: 1200px) {
        max-height: rem(700);
    }
}

/* with border */
.pre-footer--bordered {
  border-top: 10px solid $color-grey;
}