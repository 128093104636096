.usp-slider {
  background: url(/static/img/backgrounds/maco/gradient-bg.png);
    background-size: cover;
    background-repeat: no-repeat;
  position: relative;
}
.usp-slider .slick-track {
  display: flex;
}
.usp-slider .slick-slide {
  display: flex;
  height: auto;
  justify-content: center;
}
.usp-slider__head {
    padding: rem(15) rem(15) 0;

    @media screen and (min-width: 768px) {
        padding: rem(62) 0 0;
    }
}
.usp-slider__item {
    padding: rem(15);
    position: relative;

    @media screen and (min-width: 768px) {
        padding: rem(55) rem(50);
    }
}
.usp-slider__item__icon {
  font-size: rem(90);

    @media screen and (min-width: 768px) {
        font-size: rem(130);
    }
}
.usp-slider__item__img {
  display: block;
  margin: 0 auto;
  width:auto;
  height:90px;

  @media screen and (min-width: 768px) {
    height:130px;
  }
}
.usp-slider__item__title {
  margin-top: rem(20);
  margin-bottom: rem(8);
  font-size: rem(28);

    @media screen and (min-width: 768px) {
        font-size: rem(32);
        margin-top: rem(75);
        margin-bottom: rem(12);
    }
}
.usp-slider__item__text {
  flex-grow: 1;
}
@media screen and (min-width: 768px) {
    .usp-slider .slick-arrow {
        left: rem(20);
    }
    .usp-slider .slick-arrow.slick-next {
        left: auto;
        right: rem(20);
    }
}
.usp-slider .slick-arrow:hover {
  color: #fff;
}
.usp-slider__item__more {
    position: absolute;
    bottom: rem(50);
    left: 50%;
    transform: translateX(-50%);
}