.hotspot-container {
  position: relative;
}
.hotspot {
  position: absolute;
  width: rem(32);
  height: rem(34);
  transform: translateY(-50%) translateX(-50%);

  @media screen and (min-width: 768px) {
    width: rem(40);
    height: rem(45);
  }
}
.hotspot__toggle:after,
.hotspot__toggle:before {
  font-family: 'iconfont';
  speak: none;
  line-height: 1;
  content: $icon-hexagon;
  position: absolute;
  top: 0;
  left: rem(-3);
  transform: rotate(-90deg);
  color: rgba(0,0,0,.5);
  font-size: rem(30);

  @media screen and (min-width: 768px) {
    font-size: rem(40);
  }
}
.hotspot__toggle:after {
  color: $secondary;
  font-size: rem(22);
  transform: rotate(-90deg) translateX(-4px) translateY(4px);
  transition: transform 100ms ease-in-out;
  transform-origin: center;

  @media screen and (min-width: 768px) {
    font-size: rem(30);
    transform: rotate(-90deg) translateX(rem(-5)) translateY(rem(6));
  }
}
/*.hotspot__toggle:hover:after {
  transform: rotate(-90deg) translateX(-14%) translateY(17%) scale(1.32);

  @media screen and (min-width: 768px) {
    transform: rotate(-90deg) translateX(-14%) translateY(20%) scale(1.32);
  }
}*/
.hotspot__toggle__icon {
  position: absolute;
  top: rem(8);
  left: rem(7);
  font-size: rem(13);
  color: #fff;
  z-index: 1;

  @media screen and (min-width: 768px) {
    font-size: rem(15);
    top: rem(11);
    left: rem(13);
  }
}
.hotspot__text {
  display: none;
  position: absolute;
  top: rem(-20);
  left: 50%;
  transform: translateX(-50%) translateY(-100%);
  background: $secondary;
  color: #fff;
  border: rem(1) solid #fff;
  white-space: nowrap;
  font-size: rem(15);
  line-height: (18/15);
  padding: rem(16) rem(18);
}
.hotspot__text:after {
  content: '';
  position: absolute;
  left: 50%;
  top: 100%;
  height: rem(19);
  width: rem(1);
  background: #fff;
  transform: translateX(-50%);
}

.hotspot.is-open .hotspot__text {
  display: block;
}

/* hotspot details */
.hotspot-detail {
  background: rgba(244, 244, 244, 0.95);
  display: none;
  position: relative;
  margin-top: rem(10);

  @media screen and (min-width: 768px) {
    display: block;
    margin-top: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    visibility: hidden;
    transition: opacity 250ms ease-in-out;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.is-open.hotspot-detail {
  display: block;

  @media screen and (min-width: 768px) {
    opacity: 1;
    visibility: visible;
  }
}
.hotspot-detail__content {
  max-height: 100%;
  padding: rem(50) rem(15) rem(15);

  @media screen and (min-width: 768px) {
    padding: rem(30);
  }

  @media screen and (min-width: 1200px) {
    padding: rem(90);
  }
}
.hotspot-detail__close {
  position: absolute;
  right: rem(15);
  top: rem(15);

  @media screen and (min-width: 768px) {
    right: rem(32);
    top: rem(32);
  }
}