.input-box__input {
    position: absolute;
    width: rem(1);
    height: rem(1);
    margin: rem(-1);
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}
.input-box label {
    display: block;
    cursor: pointer;
}
.input-box__box {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: rem(24) rem(25) rem(10) rem(12);
    line-height: calc(22/17);
    max-width: 100%;
    position: relative;
    transition: background 200ms ease-in-out;
    height: 100%;
    font-size: rem(13);
    background: $color-grey-light;

    @media screen and (min-width: 768px) {
        font-size: rem(17);
        padding: rem(24) rem(45) rem(10) rem(14);
    }
}
.input-box__box__icon {
    position: absolute;
    right: rem(10);
    top: rem(7);
    font-size: rem(15);
    transition: color 200ms ease-in-out;
    color: $secondary;

    @media screen and (min-width: 768px) {
        font-size: rem(18);
    }
}

.input-box__input:checked ~ .input-box__box,
.input-box:hover .input-box__box {
    background: $color-grey-medium;
}
.input-box__input:checked ~ .input-box__box .input-box__box__icon:before{
    content: $icon-minus;
}

/* small input box */
.input-box--sm .input-box__box {
    @media screen and (min-width: 768px) {
        padding: rem(16) rem(35) rem(8) rem(14);
    }
}