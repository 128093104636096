.modal-dialog {
    @media screen and (min-width: 768px) {
        max-width: rem(840);
    }
}
.modal-header {
  border-bottom: 0;
  padding: rem(15) rem(30) 0 rem(20);
  position: relative;

  @media screen and (min-width: 768px) {
    padding: rem(40) rem(62) 0;
  }
}
.modal-header__close {
  position: absolute;
  right: rem(16);
  top: rem(16);
  font-size: rem(16);
  opacity: 1;
  transition: color 200ms ease-in-out;
}
.modal-header__close:hover {
  color: $secondary;
  opacity: 1;
}
.modal-title {
  font-size: rem(22);
}
.modal-body {
  padding: rem(15) rem(20) rem(20);

  @media screen and (min-width: 768px) {
    padding: rem(36) rem(62) rem(62);
  }
}