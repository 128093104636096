.data-row {
    position: relative;

    @media screen and (min-width: 768px) {
        border-top: rem(1) solid $color-grey;
    }
}
@media screen and (min-width: 768px) {
    .data-row:nth-child(odd) {
        background: $color-grey-light;
    }
    .data-row:nth-child(odd) .expandable-block__more,
    .data-row:nth-child(odd) .expandable-block__less {
        background: $color-grey-light;
    }
}
@media screen and (max-width: 767px) {
    .data-row + .data-row {
        margin-top: rem(20);
    }
}
.data-row__date {
    padding: 10px;
    height: 100%;
    min-height: 1px;

    @media screen and (min-width: 768px) {
        padding: 0;
        width: rem(45);
    }
}
.data-row__date__text {
    @media screen and (min-width: 768px) {
        position: absolute;
        left: 50%;
        top: 50%;
        transform-origin: left top;
        transform: rotate(-90deg) translateX(-50%) translateY(-50%);
    }
}
.data-row__col {
    padding: rem(10);
    height: 100%;
    border: rem(1) solid $color-grey;
    border-top: 0;

    @media screen and (min-width: 768px) {
        padding: rem(18);
        border: 0;
        border-left: rem(1) solid $color-grey;
        display: flex;
        align-items: center;
    }
}
.data-row__col--lg {
    font-size: rem(16);
    line-height: calc(28/20);

    @media screen and (min-width: 768px) {
        font-size: rem(20);
    }
}

.data-row__col--title {
    @media screen and (max-width: 767px) {
        font-family: $font-default-bold;
        font-weight: 400;
    }
}

.data-sorting__col {
    border-left: 1px solid $color-grey;
}
.data-row__link-icon {
    font-size: rem(14);
    width: rem(15);
    vertical-align: -.15em;
}


.data-sorting__title {
    padding: 20px 25px;
    font-size: 18px;
    line-height: 22px;
}
/* sort buttons */
.data-sorting__sort {
    width: 45px;
    font-size: 20px;
    padding: 10px 5px;
    line-height: 1;
    height: 100%;
}
.data-sorting__sort:hover,
.data-sorting__sort.is-active {
    background: $secondary;
}