.form-control-label,
.control-label {
    font-size: rem(12);
    line-height: (14/12);
    padding-left: rem(8);
    margin-bottom: rem(8);

    @media screen and (min-width: 768px) {
        padding-left: rem(18);
    }
}