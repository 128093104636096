.map-infobox {
    background: #fff;
    box-shadow: 0 0 rem(4) 0 rgba(0,0,0,0.3);
    padding: rem(15) rem(20) rem(30) rem(20);
    font-family: $font-default;
    font-weight: $font-default-weight;
    font-size: rem(14);
    line-height: (18/14);
    position: relative;

    @media screen and (min-width: 768px) {
        padding: rem(22) rem(25) rem(30) rem(25);
    }
}
.map-infobox:after {
    font-family: 'iconfont';
    content: $icon-poi;
    position: absolute;
    bottom: -18px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 30px;
    color: $primary;
}
.infobox--poi-turquoise:after {color: $secondary;}
.infobox--poi-orange:after {color: $color-orange;}
.infobox--poi-brown:after {color: $color-brown;}
.infobox--poi-purple:after {color: $color-purple;}

.map-infobox__close {
    position: absolute;
    top: rem(13);
    right: rem(8);
}
.map-infobox__title {
    font-size: rem(16);
    margin-bottom: 0;
}
.map-infobox__address {
    line-height: (22/14);
}
.map-infobox__detail{
    margin-top: rem(20);
}
.map-infobox__contact__icon {
    vertical-align: -0.2em;
    margin-right: 9px;
}