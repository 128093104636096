.nav-item__dropdown {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: $color-bg-default;
    color: #fff;
    padding: rem(30) rem(15);

    @media screen and (min-width: 768px) {
        bottom: auto;
        display: none;
        top: 100%;
        padding: rem(25) rem(10) rem(0);
    }
}

.nav-item__dropdown.dropdown-lg {
    padding-bottom: rem(80);
    background-image: url(/static/img/flexigons/flexigon-white-square.svg);
    background-repeat: no-repeat;
    background-position:-455px -35px;
    background-size:1000px 1000px;
}

.nav-item__dropdown.dropdown-lg li a {
    padding: 0 0 rem(16);
}

.nav-item__dropdown-container {
    @media screen and (min-width: 768px) {
        max-width: calc(1470rem/16);
        padding-left: rem(132);
        margin-left: auto;
        margin-right: auto;
    }

    @media screen and (min-width: 1200px) {
        padding-left: rem(175);
    }
}

.list-styled{
    padding-left: rem(30);
}

.list-styled li:before {
    content: '';
    border-bottom: solid 1px;
    border-right: solid 1px;
    border-color: $color-grey-dark;
    position: absolute;
    margin-top: 5px;
    margin-left: -20px;
    width: 9px;
    height: 9px;
    transform: translateY(51%) rotate(-45deg);
}

.nav-item.is-open .nav-item__dropdown {
    display: block;
}

@media screen and (min-width: 768px) {
    .nav-item:hover .nav-item__dropdown,
    .nav-link:hover ~ .nav-item__dropdown {
        display: block;
    }
    .nav-item__dropdown li {
        margin-left: rem(-4);
        display: inline-block;
    }
    .nav-item__dropdown li a {
        font-size: rem(14);
        padding: 0 rem(16) rem(24);
        display: inline-block;
        line-height: 1.2;
    }
    /*.nav-item__dropdown li:first-child  > a{
        padding-left: rem(4);
    }*/
}
.nav-item__dropdown li:hover > a,
.nav-item__dropdown li a:hover {
    color: $secondary;
}

/* dropdown subnav */
.nav-item__dropdown__subnav {
    display: none;

    @media screen and (min-width: 768px) {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        margin-top: rem(-1);
        background-color: $color-bg-default;
    }
}
.nav-item__dropdown__subnav ul {
    padding-bottom: rem(5);

    @media screen and (min-width: 768px) {
        border-top: rem(2) solid #565656;
        padding: rem(14) 0 rem(22);
    }
}
.nav-item__dropdown__subnav li a {
    padding-bottom: 0;
    text-transform: none;

    @media screen and (min-width: 768px) {
        text-transform: uppercase;
    }
}

@media screen and (min-width: 768px) {
    .nav-item__dropdown li:hover > .nav-item__dropdown__subnav {
        display: block;
    }
}