.main-footer {
  border-top: rem(10) solid #000;
  padding: rem(70) 0 rem(20);
  font-size: rem(14);
  line-height: (21/18);
  color: #fff;
  background: url(/static/img/flexigons/flexigon-white-square.svg) $color-bg-default no-repeat;
  background-repeat: no-repeat;
  background-position:-130px -555px;
  background-size:700px 700px;

    @media screen and (min-width: 768px) {
        padding: rem(40) 0 rem(30);
        font-size: rem(16);
    }

    @media screen and (min-width: 1200px) {
        padding: rem(75) 0 rem(65);
        font-size: rem(18);
        background-position:10% -600px;
        background-size: 800px 800px;
    }

    @media screen and (min-width: 1400px) {
        background-position:33% -600px;
    }
}
.main-footer__social-list {
    font-size: rem(24);
    margin-bottom: rem(15);

    @media screen and (min-width: 768px) {
        font-size: rem(30);
        margin-bottom: rem(25);
    }

    @media screen and (min-width: 1200px) {
        margin-bottom: rem(52);
    }
}
.main-footer__social-list li:not(:last-child) {
    margin-right: rem(12);

    @media screen and (min-width: 768px) {
        margin-right: rem(38);
    }
}
.main-footer__links {
    font-size: rem(14);
    line-height: (19/16);
    margin-bottom: rem(10);
    margin-top: rem(20);

    @media screen and (min-width: 768px) {
        margin-top: 0;
        margin-bottom: 0;
    }

    @media screen and (min-width: 1200px) {
        font-size: rem(16);
    }
}
.main-footer__links li {
  display: inline-block;
  padding: rem(2) rem(10) rem(2) rem(6);
  border-right: rem(1) solid #565656;

  @media screen and (min-width: 768px) {
      padding: rem(10) rem(15) rem(10) rem(11);
  }

  @media screen and (min-width: 1200px) {
      padding: rem(10) rem(28) rem(10) rem(24);
  }
}