.link-box {
    padding: rem(28) rem(25) rem(10) rem(12);
    line-height: calc(22/17);
    max-width: 100%;
    position: relative;
    transition: color 200ms ease-in-out, background 200ms ease-in-out;
    height: 100%;
    font-size: rem(13);

    @media screen and (min-width: 768px) {
        font-size: rem(17);
        padding: rem(35) rem(45) rem(10) rem(12);
    }
}
.link-box__icon {
    position: absolute;
    right: rem(10);
    top: rem(10);
    font-size: rem(15);
    transition: color 200ms ease-in-out;
    color: $secondary;

    @media screen and (min-width: 768px) {
        font-size: rem(18);
    }
}
.link-box:hover {
    background: $primary;
    color: #fff;
}
.link-box:hover .link-box__icon {
    color: #fff;
}