.nav-teaser__title {
    background: $color-grey-dark;
}

.nav-teaser__dropdown {
    background-position: center;
    background-size: cover;
}

.nav-teaser__overlay{
    background-color: rgba(0,0,0,.2);
}

.nav-teaser__dropdown li {
    display: block !important;
}

.link-unstyled:hover {
    color: #FFF !important;
}