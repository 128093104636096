select.form-control:not([size]):not([multiple]) {
    height: calc(50rem / 16);

    @media screen and (min-width: 768px) {
        height: calc(50rem / 16);
    }
}
.select {
    position: relative;
}
.select>select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}
.select select::-ms-expand {
    display: none;
}
.select:after {
    font-family: "iconfont";
    content: $icon-arrow-down;
    speak: none;
    position: absolute;
    top: 50%;
    right: rem(10);
    transform: translateY(-50%);
    pointer-events: none;
    font-size: rem(12);
    color: $secondary;

    @media screen and (min-width: 768px) {
        right: rem(20);
    }
}